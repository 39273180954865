import { createLogger, createStore } from "vuex";
import auth from "./modules/auth";
import portfolio from "./modules/portfolio";
import security from "@/store/modules/security";
import user from "@/store/modules/user";
import transaction from "@/store/modules/transaction";
import country from "@/store/modules/country";
import company from "@/store/modules/company";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    auth,
    portfolio,
    transaction,
    security,
    user,
    country,
    company,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
