<template>
  <div>
    <base-nav
      :show="showMenu"
      type="light"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      expand="lg"
      @change="toggleNavbar"
    >
      <template v-slot:brand>
        <div class="navbar-wrapper">
          <router-link
            class="navbar-brand"
            :to="{
              name: 'Index',
              params: { lang: $route.params.lang },
            }"
          >
            <img src="/img/brand/logobetav1.svg" />
          </router-link>
        </div>
      </template>

      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link
              :to="{
                name: 'Index',
                params: { lang: $route.params.lang },
              }"
            >
              <img src="/img/brand/logobetav1.svg" />
            </router-link>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>

      <ul class="navbar-nav mr-auto">
        <li class="nav-item" v-if="isAuthenticated">
          <router-link
            :to="{
              name: 'Dashboard',
              params: { lang: $route.params.lang },
            }"
            class="nav-link"
          >
            <span class="nav-link-inner--text">{{ t("Home") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{
              name: 'Plans',
              params: { lang: $route.params.lang },
            }"
            class="nav-link"
          >
            <span class="nav-link-inner--text">{{ t("Plans") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{
              name: 'Login',
              params: { lang: $route.params.lang },
            }"
            class="nav-link"
            v-if="!isAuthenticated"
          >
            <span class="nav-link-inner--text">{{ t("Login") }}</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="!isAuthenticated">
          <router-link
            :to="{
              name: 'Register',
              params: { lang: $route.params.lang },
            }"
            class="nav-link"
          >
            <span class="nav-link-inner--text">{{ t("Register") }}</span>
          </router-link>
        </li>
      </ul>
      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <span v-if="locale === 'ru'">
            <router-link
              :to="{
                name: $route.name,
                params: Object.assign({}, $route.params, { lang: 'en' }),
              }"
              class="nav-link"
            >
              <span class="nav-link-inner--text">EN</span>
            </router-link>
          </span>
          <span v-else>
            <router-link
              :to="{
                name: $route.name,
                params: Object.assign({}, $route.params, { lang: 'ru' }),
              }"
              class="nav-link"
            >
              <span class="nav-link-inner--text">RU</span>
            </router-link>
          </span>
        </li>
      </ul>
      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-5">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/stockwayup"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Like us on Facebook"
          >
            <i class="fab fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/stockwayup"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Follow us on Instagram"
          >
            <i class="fab fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/stockwayup"
            target="_blank"
            data-toggle="tooltip"
            data-original-title="Follow us on Twitter"
          >
            <i class="fab fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>
      </ul>
    </base-nav>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              © {{ year }}
              <a
                href="https://stockwayup.com"
                class="font-weight-bold ml-1"
                target="_blank"
                >Stockwayup</a
              >
            </div>
          </div>
          <div class="col-xl-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-xl-end"
            >
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'Plans',
                    params: { lang: $route.params.lang },
                  }"
                  class="nav-link"
                >
                  <span class="nav-link-inner--text">{{ t("Plans") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'Login',
                    params: { lang: $route.params.lang },
                  }"
                  class="nav-link"
                  v-if="!isAuthenticated"
                >
                  <span class="nav-link-inner--text">{{ t("Login") }}</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'Register',
                    params: { lang: $route.params.lang },
                  }"
                  class="nav-link"
                >
                  <span class="nav-link-inner--text">{{ t("Register") }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
    isAuthenticated() {
      return (
        this.$store.getters.isAuthenticated && this.$store.getters.isTokenValid
      );
    },
  },
  methods: {
    toggleNavbar(open) {
      // document.body.classList.toggle("nav-open");
      this.showMenu = open;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to) {
        if (this.$route.params.lang === "en") {
          this.locale = "en";
          this.$router.replace({ name: to.name });
        } else if (this.$route.params.lang === "") {
          this.locale = "en";
        } else if (this.$route.params.lang === "ru") {
          this.locale = "ru";
        }

        this.updateBackground();
      },
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
