import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getCountries: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("countries")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getCountriesWithMM: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("countries")
          .get({
            filter: {
              with_mm: true,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
  },
};
