<template>
  <stats-card>
    <!-- Card body -->
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4"></div>
      <div class="col-4 text-right">
        <base-button
          type="primary"
          outline
          size="sm"
          @click="openAddToPortfolioModal"
          tag="a"
        >
          {{ t("Add to") }} <i class="ni ni-briefcase-24"></i>
        </base-button>
      </div>
    </div>
    <div class="row">
      <!--      <div class="d-none d-lg-block col-lg-auto">-->
      <!--        <div-->
      <!--          class="icon icon-shape bg-success text-white rounded-circle shadow"-->
      <!--        >-->
      <!--          <i class="ni ni-atom"></i>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-12 col-md-7 col-lg-6">
        <span class="h2 font-weight-bold mb-0">
          <router-link
            :to="{
              name: 'Security',
              params: { id: security.id, lang: $route.params.lang },
            }"
            v-if="relation"
            >{{ relation.name }}
          </router-link>
        </span>
        <p>
          <span class="h6 font-weight-bold text-muted mb-0" v-if="relation">{{
            relation.ticker
          }}</span>
        </p>
      </div>
      <div class="col-12 col-md-5 col-lg-3">
        <span
          class="h2 font-weight-bold mb-0 mr-1 text-nowrap"
          v-if="security.quote"
          ><price :value="security.quote.close"></price>
        </span>
        <span
          class="h4 font-weight-bold mb-0 text-nowrap"
          v-if="security.quote && security.currency"
        >
          {{ security.currency.name }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="mt-3 mb-0 text-sm" v-if="security.company">
          <span class="h4 font-weight-bold text-muted mb-0">P/E</span
          ><span class="h4 font-weight-bold mb-0">{{
            security.company.pe_ratio
          }}</span>
        </p>
      </div>
      <div class="col-8">
        <p class="mt-3 mb-0 text-sm text-right">
          <span
            class="text-success mr-2"
            v-if="
              security.quote && security.quote.percent_change.search('\-') !== 0
            "
            ><i class="fa fa-arrow-up"></i>&nbsp;<change-price
              :value="security.quote.percent_change"
            ></change-price
            >%</span
          >
          <span
            class="text-warning mr-2"
            v-if="
              security.quote && security.quote.percent_change.search('\-') === 0
            "
            ><i class="fa fa-arrow-down"></i>&nbsp;<change-price
              :value="security.quote.percent_change"
            ></change-price
            >%</span
          >
          <span class="text-nowrap" v-if="security.quote">{{ t("1d") }}</span>
        </p>
      </div>
    </div>
  </stats-card>
</template>

<script>
import StatsCard from "../../components/Cards/StatsCard";
import Price from "../Widgets/Price";
import ChangePrice from "../Widgets/ChangePrice";
import { useI18n } from "vue-i18n";

export default {
  name: "Security",
  components: {
    ChangePrice,
    Price,
    StatsCard,
  },
  props: {
    security: {
      type: Object,
      require: true,
    },
    showAddToPortfolioModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return {
      t,
      locale,
    };
  },
  computed: {
    type: function () {
      if (this.security.stock !== undefined) {
        return "Stock";
      }

      if (this.security.bond !== undefined) {
        return "Bond";
      }

      if (this.security.fund !== undefined) {
        return "Fund";
      }

      return "";
    },

    relation: function () {
      if (this.security.stock !== undefined) {
        return this.security.stock;
      }

      if (this.security.bond !== undefined) {
        return this.security.bond;
      }

      if (this.security.fund !== undefined) {
        return this.security.fund;
      }

      return null;
    },
  },
  methods: {
    onClick() {
      this.$router.push({ name: "Security", params: { id: this.security.id } });
    },
    openAddToPortfolioModal() {
      this.$emit("openAddToPortfolioModal", this.security.id);
    },
  },
};
</script>

<style scoped></style>
