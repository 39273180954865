<template>
  {{ formatPrice(value) }}
</template>

<script>
import Big from "big.js";

export default {
  name: "price",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatPrice(val) {
      if (val === "") {
        return val;
      }

      let bigVal = new Big(val);

      if (bigVal.cmp(new Big(1)) === 1) {
        return bigVal.toFixed(2);
      }

      return val;
    },
  },
};
</script>
