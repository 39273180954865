<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <template v-slot:header>
                <h3 class="mb-0">{{ t("Create portfolio") }}</h3>
              </template>
              <!-- Card body -->
              <Form @submit="onSubmit" :validation-schema="schema" ref="form">
                <!-- Input groups with icon -->
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      alternative
                      name="name"
                      :placeholder="t('Portfolio name')"
                    >
                    </base-input>
                  </div>
                </div>

                <!--                <div class="row">-->
                <!--                  <tags-input-->
                <!--                    placeholder="Add new tag"-->
                <!--                    class="test"-->
                <!--                  ></tags-input>-->
                <!--                </div>-->

                <div class="row">
                  <button class="btn btn-success">{{ t("Create") }}</button>
                </div>
              </Form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Form } from "vee-validate";
import * as Yup from "yup";
import router from "@/router";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { setLocale as YupSetLocale } from "yup";

export default {
  name: "portfolio-add",
  components: {
    RouteBreadCrumb,
    // TagsInput,
    Form,
  },
  data() {
    return {
      model: {
        name: "",
      },
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    YupSetLocale({
      mixed: {
        default: t("${path} is invalid", { path: "{path}" }),
        required: t("${path} is a required field", { path: "{path}" }),
      },
      string: {
        min: t("${path} must be at least ${min} characters", {
          path: "{path}",
          min: "{min}",
        }),
        max: t("${path} must be less than ot equal ${max} characters", {
          path: "{path}",
          max: "{max}",
        }),
      },
    });

    const schema = Yup.object().shape({
      name: Yup.string().min(2).max(255).required().label(t("Portfolio name")),
    });

    return {
      schema,
      t,
      locale,
    };
  },
  methods: {
    async onSubmit(values) {
      try {
        await this.$store.dispatch("createPortfolio", values);

        this.runToast(
          "top-right",
          "success",
          this.t("Portfolio created"),
          "ni ni-bold-down"
        );

        router.push({
          name: "Portfolios root",
          params: { lang: this.$route.params.lang },
        });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          let errors = {};

          for (const [key, value] of Object.entries(
            err.response.data.mappedErrors
          )) {
            errors[key] = this.t(value);
          }

          this.$refs.form.setErrors(errors);
        } else {
          this.processError(err);
        }
      }
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
