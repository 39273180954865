<template>
  <form @submit="onSubmit">
    <!-- Input groups with icon -->

    <div class="form-group row">
      <label class="col-md-2 col-form-label form-control-label">{{
        t("Date")
      }}</label>
      <div class="col-md-10">
        <base-input name="date" type="date" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label form-control-label">{{
        t("Type")
      }}</label>
      <div class="col-md-10">
        <base-input name="direction" id="direction">
          <base-radio
            name="direction"
            class="mb-3"
            value="1"
            v-model="values.direction"
          >
            {{ t("Buy") }}
          </base-radio>

          <base-radio
            name="direction"
            class="mb-3"
            value="2"
            v-model="values.direction"
          >
            {{ t("Sell") }}
          </base-radio>
        </base-input>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label form-control-label">{{
        t("Number")
      }}</label>
      <div class="col-md-10">
        <base-input
          name="amount"
          type="number"
          min="1"
          max="9007199254740991"
          placeholder="0"
          step="1"
          @input="calcCost"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-2 col-form-label form-control-label">{{
        t("Price")
      }}</label>
      <div class="col-md-10">
        <base-input
          name="price"
          type="number"
          min="0"
          max="9007199254740991"
          placeholder="0"
          step="any"
          @input="calcCost"
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="cost" class="col-md-2 col-form-label form-control-label">{{
        t("Cost")
      }}</label>
      <div class="col-md-10">
        <base-input name="cost" disabled />
      </div>
    </div>

    <div class="row">
      <button class="btn btn-success" v-if="!processing">
        {{ t("Save") }}
      </button>

      <button class="btn btn-success" disabled v-if="processing">
        <i class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </form>
</template>

<script>
import { useForm } from "vee-validate";

import {
  date as YupDate,
  number as YupNumber,
  object as YupObject,
  setLocale as YupSetLocale,
} from "yup";

import { add as addDate, format as formatDate } from "date-fns";
import Big from "big.js";
import { useI18n } from "vue-i18n";

const maxSafeInteger = 9007199254740991;

export default {
  name: "transactions-add",
  setup(props) {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    YupSetLocale({
      mixed: {
        notType: () => {
          return t("Invalid value");
        },
        default: t("${path} is invalid", { path: "{path}" }),
        required: t("${path} is a required field", { path: "{path}" }),
      },
      number: {
        min: t("${path} must be greater than or equal to ${min}", {
          path: "{path}",
          min: "{min}",
        }),
        max: t("${path} must be less than or equal to ${max}", {
          path: "{path}",
          min: "{max}",
        }),
        integer: t("${path} must be an integer", { path: "{path}" }),
      },
    });

    const maxDate = addDate(new Date(), { days: 1 });
    const minDate = new Date("1900-01-01 00:00:00");

    const schema = {
      values: {
        direction: props.direction,
        date: props.date,
        amount: props.amount,
        price: props.price,
        cost: new Big(props.amount).times(new Big(props.price)).toFixed(),
      },
      validation: YupObject({
        date: YupDate()
          .required()
          .max(
            maxDate,
            t("Date field must be at earlier than") +
              " " +
              formatDate(maxDate, "dd.MM.yyyy")
          )
          .min(
            minDate,
            t("Date field must be at later than") +
              " " +
              formatDate(minDate, "dd.MM.yyyy")
          )
          .label(t("Date")),
        amount: YupNumber()
          .required()
          .integer()
          .min(1)
          .max(maxSafeInteger)
          .label(t("Number")),
        price: YupNumber()
          .required()
          .min(
            0.000000001,
            t("Price must be greater than or equal to 0.000000001")
          )
          .max(maxSafeInteger)
          .label(t("Price")),
        direction: YupNumber().required(),
      }),
    };

    const { handleSubmit, setFieldValue, values, setErrors, validate } =
      useForm({
        validationSchema: schema.validation,
        initialValues: schema.values,
      });

    return {
      handleSubmit,
      setFieldValue,
      values,
      setErrors,
      validate,
      t,
      locale,
    };
  },
  props: {
    date: {
      type: String,
      required: false,
      default: formatDate(new Date(), "yyyy-MM-dd"),
    },
    amount: {
      type: String,
      required: false,
      default: "0",
    },
    price: {
      type: String,
      required: false,
      default: "0",
    },
    direction: {
      type: String,
      required: false,
      default: "1",
    },
  },
  data: function () {
    return {
      processing: false,
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      this.processing = true;

      await this.validate().then((result) => {
        if (result.valid) {
          this.$emit("valid", this.values);
        }
      });

      this.processing = false;
    },
    calcCost() {
      let cost = 0;

      if (this.values.amount && this.values.price) {
        cost = new Big(this.values.amount).times(new Big(this.values.price));
      }

      this.setFieldValue("cost", cost.toFixed());
    },
  },
};
</script>

<style scoped></style>
