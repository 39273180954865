import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getDashboardPortfolios: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .findAll("portfolio", {
            page: {
              number: 1,
              size: 6,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolio: (context, id) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .find("portfolio", id)
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioSecurities: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .all("security")
          .get({
            include: "stock,bond,quotes",
            page: {
              size: payload.size,
              cursor: payload.cursor,
              sort: payload.sort,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolios: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .find("portfolio")
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    createPortfolio: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .create("portfolio", payload)
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    updatePortfolio: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .patch(payload.data)
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    deletePortfolio: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .destroy()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    addToPortfolio: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);

        jsonApi
          .one("portfolio", payload.pid)
          .relationships("securities")
          .post([{ id: payload.sid }])
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    deleteFromPortfolio: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);

        jsonApi
          .one("portfolio", payload.pid)
          .relationships("securities")
          // .one("security", payload.sid)
          .destroy([{ id: payload.sid, type: "securities" }])
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioNews: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .all("news")
          .get({
            include: "cats,source,stocks",
            page: {
              size: 5,
              cursor: payload.cursor,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfoliosNews: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("users", payload.uid)
          .all("news")
          .get({
            include: "cats,source,stocks",
            page: {
              size: 5,
              cursor: payload.cursor,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioEarnings: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.id)
          .all("earnings")
          .get({
            include: "stock",
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioDividends: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.id)
          .all("dividends")
          .get({
            include: "stock",
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfoliosEarnings: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("users", payload.uid)
          .all("earnings")
          .get({
            include: "stock",
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfoliosDividends: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("users", payload.uid)
          .all("dividends")
          .get({
            include: "stock",
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioDayPrices: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .all("day-price")
          .get({
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfolioDayPricesPeriods: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .all("day-price-period")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
  },
};
