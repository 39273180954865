import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "../views/Layout/DashboardLayout";
import AuthLayout from "../views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Calendar from "../views/Calendar/Calendar.vue";

// Pages
import Profile from "../views/Pages/UserProfile.vue";

// Portfolios
import PortfoliosLayout from "../views/Portfolios/Layout.vue";
import PortfolioLayout from "../views/Portfolios/PortfolioLayout.vue";
import PortfolioList from "../views/Portfolios/List.vue";
import PortfolioView from "../views/Portfolios/Portfolio/View.vue";
import PortfolioHoldings from "../views/Portfolios/Portfolio/Holdings.vue";
import PortfolioAdd from "../views/Portfolios/Add.vue";

// Transactions
import TransactionsLayout from "../views/Portfolios/Portfolio/Transactions/Layout.vue";
import TransactionsList from "../views/Portfolios/Portfolio/Transactions/List.vue";
import TransactionsAdd from "../views/Portfolios/Portfolio/Transactions/Add.vue";
import TransactionsEdit from "../views/Portfolios/Portfolio/Transactions/Edit.vue";

// Screener
import ScreenerLayout from "../views/Screener/Layout.vue";
import Screener from "../views/Screener/Screener.vue";

//import middleware
import auth from "../middleware/auth.js";
import guest from "../middleware/guest.js";
import SecurityView from "../views/Security/View.vue";
import CatchAll from "../views/CatchAll";

const Index = () => import("../views/Pages/Index.vue");
const Login = () => import("../views/Pages/Login.vue");
const Register = () => import("../views/Pages/Register.vue");
const Reset = () => import("../views/Pages/Reset/Reset.vue");
const ResetConfirmation = () => import("../views/Pages/Reset/Confirmation.vue");
const Pricing = () => import("../views/Pages/Pricing.vue");
const NotFound = () => import("../views/NotFound");

const routes = [
  {
    name: "Dashboard root",
    path: "/:lang(ru|en)?/dashboard",
    redirect: { name: "Dashboard" },
    component: DashboardLayout,
    children: [
      {
        name: "Portfolios root",
        path: "/:lang(ru|en)?/portfolios",
        redirect: { name: "Portfolios" },
        components: { default: PortfoliosLayout },
        children: [
          {
            name: "Create portfolio",
            path: "/:lang(ru|en)?/portfolios/add",
            components: { default: PortfolioAdd },
            meta: { middleware: auth },
          },
          {
            name: "Portfolio root",
            path: "/:lang(ru|en)?/portfolios/:id",
            redirect: { name: "Portfolio" },
            components: { default: PortfolioLayout },
            children: [
              {
                name: "Transactions root",
                path: "/:lang(ru|en)?/portfolios/:id/securities/:sid/transactions",
                redirect: { name: "Transactions" },
                components: { default: TransactionsLayout },
                children: [
                  {
                    name: "Transactions",
                    path: "",
                    components: { default: TransactionsList },
                    meta: { middleware: auth },
                  },
                  {
                    name: "Add transaction",
                    path: "/:lang(ru|en)?/portfolios/:id/securities/:sid/transactions/add",
                    components: { default: TransactionsAdd },
                    meta: { middleware: auth },
                  },
                  {
                    name: "Edit transaction",
                    path: "/:lang(ru|en)?/portfolios/:id/securities/:sid/transactions/:tid/edit",
                    components: { default: TransactionsEdit },
                    meta: { middleware: auth },
                  },
                ],
              },
              {
                name: "Holdings",
                path: "/:lang(ru|en)?/portfolios/:id/holdings",
                components: { default: PortfolioHoldings },
                meta: { middleware: auth },
              },
              {
                name: "Portfolio",
                path: "",
                components: { default: PortfolioView },
                meta: { middleware: auth },
              },
            ],
          },
          {
            name: "Portfolios",
            path: "",
            components: { default: PortfolioList },
            meta: { middleware: auth },
          },
        ],
      },
      {
        name: "Screener root",
        path: "/:lang(ru|en)?/screener",
        redirect: { name: "Screener" },
        components: { default: ScreenerLayout },
        children: [
          {
            path: "/:lang(ru|en)?/securities/:id",
            name: "Security",
            components: { default: SecurityView },
            meta: { middleware: auth },
          },
          {
            path: "",
            name: "Screener",
            components: { default: Screener },
            meta: { middleware: auth },
          },
        ],
      },
      {
        path: "/:lang(ru|en)?/calendar",
        name: "Calendar",
        components: { default: Calendar },
        meta: { middleware: auth },
      },
      {
        path: "/:lang(ru|en)?/pages/user",
        name: "Profile",
        components: { default: Profile },
        meta: { middleware: auth },
      },
      {
        name: "Dashboard",
        path: "/:lang(ru|en)?/dashboard",
        components: { default: Dashboard },
        meta: { middleware: auth },
      },
    ],
  },
  {
    path: "/:lang(ru|en)?",
    component: AuthLayout,
    children: [
      {
        path: "/:lang(ru|en)?/plans",
        name: "Plans",
        components: { default: Pricing },
      },
      {
        path: "/:lang(ru|en)?/login",
        name: "Login",
        components: { default: Login },
        meta: { middleware: guest },
      },
      {
        path: "/:lang(ru|en)?/register",
        name: "Register",
        components: { default: Register },
        meta: { middleware: guest },
      },
      {
        path: "/:lang(ru|en)?/reset/step2",
        name: "Reset confirmation",
        components: { default: ResetConfirmation },
        meta: { middleware: guest },
      },
      {
        path: "/:lang(ru|en)?/reset",
        name: "Reset password",
        components: { default: Reset },
        meta: { middleware: guest },
      },
      {
        path: "/:lang(ru|en)?",
        name: "Index",
        components: { default: Index },
        meta: { middleware: guest },
      },
    ],
  },
  {
    name: "NotFound",
    path: "/404",
    components: { default: NotFound },
  },
  {
    name: "CatchAll",
    path: "/:pathMatch(.*)*",
    components: { default: CatchAll },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
