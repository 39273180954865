<template>
  <card>
    <template v-slot:header>
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">{{ t("Edit profile") }}</h3>
        </div>
        <!--        <div class="col-4 text-right">-->
        <!--          <a href="#!" class="btn btn-sm btn-primary">{{ t('Settings') }}</a>-->
        <!--        </div>-->
      </div>
    </template>

    <!--    <h6 class="heading-small text-muted mb-4">{{ t("Change password") }}</h6>-->

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <base-input
            name="username"
            type="text"
            :label="t('Username')"
            :placeholder="t('Username')"
            v-model="model.username"
            disabled=""
          >
          </base-input>
        </div>
      </div>
    </div>
    <hr class="my-4" />

    <h6 class="heading-small text-muted mb-4">{{ t("Email") }}</h6>

    <div class="pl-lg-4">
      <div class="row">
        <p class="text-warning col-md-12" v-if="!isEmailConfirmed">
          {{
            t(
              "Email not confirmed. Please confirm your email to keep your account safe."
            )
          }}
        </p>
      </div>

      <Form
        @submit="sendEmailConfirmationCode"
        :validation-schema="schema.validation"
        :initial-values="schema.values"
        ref="form"
      >
        <div class="row">
          <div class="col-md-6">
            <base-input
              alternative
              placeholder="jesse@example.com"
              input-classes="form-control-alternative"
              name="email"
              disabled
            />
          </div>

          <div class="col-md-6" v-if="!isEmailConfirmed">
            <a
              href="#!"
              class="btn btn-outline-info"
              @click.prevent="requestEmailConfirmationCode"
              >{{ t("Resend confirmation code") }}</a
            >
          </div>
        </div>
        <div class="row" v-if="!isEmailConfirmed">
          <div class="col-md-6">
            <base-input
              alternative
              :placeholder="t('Paste code here')"
              input-classes="form-control-alternative"
              name="confirmation-code"
            />
          </div>
          <div class="col-md-6">
            <button class="btn btn-outline-success">
              {{ t("Confirm email") }}
            </button>
          </div>
        </div>
      </Form>
    </div>

    <hr class="my-4" />

    <!--    <h6 class="heading-small text-muted mb-4">{{ t("Password") }}</h6>-->

    <!--    <div class="pl-lg-4">-->
    <!--      <div class="row">-->
    <!--        <div class="col-lg-6">-->
    <!--          <base-input-->
    <!--            alternative=""-->
    <!--            :label="t('Old password')"-->
    <!--            :placeholder="t('Old password')"-->
    <!--            input-classes="form-control-alternative"-->
    <!--            v-model="model.oldPassword"-->
    <!--            name="old-password"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="row">-->
    <!--        <div class="col-lg-6">-->
    <!--          <base-input-->
    <!--            alternative=""-->
    <!--            :label="t('New password')"-->
    <!--            :placeholder="t('New password')"-->
    <!--            type="password"-->
    <!--            input-classes="form-control-alternative"-->
    <!--            v-model="model.newPassword"-->
    <!--            name="new-password"-->
    <!--          />-->
    <!--        </div>-->

    <!--        <div class="col-lg-6">-->
    <!--          <base-input-->
    <!--            alternative=""-->
    <!--            :label="t('Confirm password')"-->
    <!--            :placeholder="t('Confirm password')"-->
    <!--            type="password"-->
    <!--            input-classes="form-control-alternative"-->
    <!--            v-model="model.confirmPassword"-->
    <!--            name="confirmation"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="row">-->
    <!--        <base-button-->
    <!--          type="primary"-->
    <!--          native-type="submit"-->
    <!--          class="btn btn-danger"-->
    <!--          >{{ t("Change password") }}</base-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
  </card>
</template>
<script>
import {
  object as YupObject,
  setLocale as YupSetLocale,
  string as YupString,
} from "yup";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import { useI18n } from "vue-i18n";

export default {
  name: "edit-profile-form",
  components: {
    Form,
  },
  data() {
    return {
      model: {
        username: "",
        email: this.$store.getters.email,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      emailConfirmationRequestId: "",
    };
  },
  computed: {
    isEmailConfirmed: function () {
      return this.$store.getters.isEmailConfirmed;
    },
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    YupSetLocale({
      mixed: {
        default: t("${path} is invalid", { path: "{path}" }),
        required: t("${path} is a required field", { path: "{path}" }),
      },
      string: {
        email: t("${path} must be a valid email", { path: "{path}" }),
        min: t("${path} must be at least ${min} characters", {
          path: "{path}",
          min: "{min}",
        }),
      },
    });

    const schema = {
      values: {
        email: store.getters.email,
      },
      validation: YupObject({
        email: YupString().email().required(),
        "confirmation-code": YupString().required().label(t("Code")),
      }),
    };

    return {
      schema,
      t,
      locale,
    };
  },
  mounted() {
    if (this.$route.query.code && this.$route.query.uuid) {
      this.emailConfirmationRequestId = this.$route.query.uuid;

      this.sendEmailConfirmationCode({
        "confirmation-code": this.$route.query.code,
      });
    }
  },
  methods: {
    async sendEmailConfirmationCode(values) {
      if (this.emailConfirmationRequestId === "") {
        this.runToast(
          "top-right",
          "danger",
          this.t("Code expired. Try resend code."),
          "ni ni-bell-55"
        );

        return;
      }

      await this.$store
        .dispatch("sendEmailConfirmationCode", {
          id: this.emailConfirmationRequestId,
          code: values["confirmation-code"],
        })
        .then(() => {
          this.$store.dispatch("refresh").catch((err) => {
            this.processError(err);
          });

          this.runToast(
            "top-right",
            "success",
            this.t("Email confirmed"),
            "ni ni-bold-down"
          );
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            let errors = {};

            for (const [key, value] of Object.entries(
              err.response.data.mappedErrors
            )) {
              errors[key] = this.t(value);
            }

            this.$refs.form.setErrors(errors);
          } else {
            this.processError(err);
          }
        });
    },
    async requestEmailConfirmationCode() {
      await this.$store
        .dispatch("requestEmailConfirmationCode")
        .then(({ data }) => {
          this.emailConfirmationRequestId = data.id;

          this.runToast(
            "top-right",
            "success",
            this.t("New confirmation code send"),
            "ni ni-bold-down"
          );
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            let errors = {};

            for (const [key, value] of Object.entries(
              err.response.data.mappedErrors
            )) {
              errors[key] = this.t(value);
            }

            this.$refs.form.setErrors(errors);
          } else {
            this.processError(err);
          }
        });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
<style></style>
