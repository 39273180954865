<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
      <div class="row">
        <portfolio
          :portfolio="portfolio"
          v-if="portfolio.id !== ''"
        ></portfolio>

        <div class="col-xl-3 col-md-6">
          <card>
            <router-link
              :to="{
                name: 'Create portfolio',
                params: { lang: $route.params.lang },
              }"
              class="btn btn-outline-success"
            >
              {{ t("Add portfolio") }}
            </router-link>
          </card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <!--                  <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>-->
                  <h5 class="h3 text-white mb-0">
                    {{ t("Portfolio summary") }}
                  </h5>
                </div>
                <div class="col">
                  <el-select
                    class="dropdown float-right"
                    :placeholder="t('Period')"
                    v-model="selectedPeriod"
                    filterable
                    @change="selectPricePeriod"
                  >
                    <el-option
                      v-for="period in periods"
                      :value="period.date"
                      :label="
                        period.date == '1 month till today'
                          ? t(period.date)
                          : period.date
                      "
                      :key="period.date"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="395" :id="dayPricesID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-4">
          <calendar-widget
            :earnings="earnings"
            @update-interval="updateCalendarInterval"
          ></calendar-widget>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-5">
          <div class="row">
            <div class="col-xl-12">
              <news
                :news="news"
                :current-page="newsCurrentPage"
                :total-pages="totalNewsPages"
                @update-current-page="updateNews"
              ></news>
            </div>
          </div>
        </div>

        <div class="col-xl-7">
          <!--          <div class="row">-->
          <!--            <div class="col-md-6">-->
          <!--              <card header-classes="bg-transparent">-->
          <!--                <template v-slot:header>-->
          <!--                  <div class="row align-items-center">-->
          <!--                    <div class="col">-->
          <!--                      &lt;!&ndash;                      <h6 class="text-uppercase text-muted ls-1 mb-1">&ndash;&gt;-->
          <!--                      &lt;!&ndash;                        Performance&ndash;&gt;-->
          <!--                      &lt;!&ndash;                      </h6>&ndash;&gt;-->
          <!--                      <h5 class="h3 mb-0">{{  t('Portfolio summary') }}</h5>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--              </card>-->
          <!--            </div>-->

          <!--            <div class="col-md-6">-->
          <!--              <card header-classes="bg-transparent">-->
          <!--                <template v-slot:header>-->
          <!--                  <div class="row align-items-center">-->
          <!--                    <div class="col">-->
          <!--                      &lt;!&ndash;                      <h6 class="text-muted text-uppercase ls-1 mb-1">&ndash;&gt;-->
          <!--                      &lt;!&ndash;                        Overview&ndash;&gt;-->
          <!--                      &lt;!&ndash;                      </h6>&ndash;&gt;-->
          <!--                      <h5 class="h3 mb-0">Income</h5>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--              </card>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row">
            <div class="col-md-12">
              <card header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">{{ t("Holdings") }}</h5>
                    </div>
                    <div class="col text-right">
                      <router-link
                        :to="{
                          name: 'Screener root',
                          params: { lang: $route.params.lang },
                        }"
                        class="btn btn-sm btn-primary"
                        >{{ t("Screener") }}
                      </router-link>

                      <router-link
                        :to="{
                          name: 'Holdings',
                          params: { lang: $route.params.lang },
                        }"
                        class="btn btn-sm btn-primary"
                        >{{ t("See all") }}
                      </router-link>
                    </div>
                  </div>
                </template>

                <div class="row">
                  <div
                    class="col-md-6"
                    v-for="(security, index) in secs"
                    :key="index"
                  >
                    <holding
                      :security="security"
                      @deleted="deleteHolding"
                    ></holding>
                  </div>
                </div>

                <template v-slot:footer v-if="totalSecsPages > 1">
                  <base-pagination
                    v-model="secsCurrentPage"
                    :page-count="totalSecsPages"
                    @update:modelValue="updatePortfolioSecs"
                    class="justify-content-end"
                  ></base-pagination>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="row">-->
      <!--        <div class="col-xl-6 mb-5 mb-xl-0">-->
      <!--          <card-->
      <!--            type="default"-->
      <!--            header-classes="bg-secondary"-->
      <!--            body-classes="bg-secondary"-->
      <!--          >-->
      <!--            <template v-slot:header>-->
      <!--              <div class="row align-items-center">-->
      <!--                <div class="col">-->
      <!--                  <h5 class="h3 mb-0">Future Growth Analysis</h5>-->
      <!--                </div>-->
      <!--                <div class="col">-->
      <!--                  <ul class="nav nav-pills justify-content-end">-->
      <!--                    <li class="nav-item mr-2 mr-md-0">-->
      <!--                      <a-->
      <!--                        class="nav-link py-2 px-3"-->
      <!--                        href="#"-->
      <!--                        :class="{ active: bigLineChart.activeIndex === 0 }"-->
      <!--                        @click.prevent="initBigChart(0)"-->
      <!--                      >-->
      <!--                        <span class="d-none d-md-block">Month</span>-->
      <!--                        <span class="d-md-none">M</span>-->
      <!--                      </a>-->
      <!--                    </li>-->
      <!--                    <li class="nav-item">-->
      <!--                      <a-->
      <!--                        class="nav-link py-2 px-3"-->
      <!--                        href="#"-->
      <!--                        :class="{ active: bigLineChart.activeIndex === 1 }"-->
      <!--                        @click.prevent="initBigChart(1)"-->
      <!--                      >-->
      <!--                        <span class="d-none d-md-block">Week</span>-->
      <!--                        <span class="d-md-none">W</span>-->
      <!--                      </a>-->
      <!--                    </li>-->
      <!--                  </ul>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </template>-->
      <!--            <div class="chart-area">-->
      <!--              <canvas></canvas>-->
      <!--            </div>-->
      <!--          </card>-->
      <!--        </div>-->

      <!--        <div class="col-xl-6 mb-5 mb-xl-0">-->
      <!--          <card>-->
      <!--            <template v-slot:header>-->
      <!--              &lt;!&ndash; Title &ndash;&gt;-->
      <!--              <h5 class="h3 mb-0">Diversification Across Industries</h5>-->
      <!--            </template>-->
      <!--            <div class="chart">-->
      <!--              <canvas></canvas>-->
      <!--            </div>-->
      <!--          </card>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Portfolio from "@/views/Portfolios/Portfolio";
import CalendarWidget from "@/views/Widgets/CalendarWidget";
import Holding from "@/views/Portfolios/Portfolio/Holding";
import {
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
} from "element-plus";
import { format as formatDate, parseISO } from "date-fns";
import News from "@/views/Widgets/News";
import Chart from "chart.js";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Big from "big.js";
import { useI18n } from "vue-i18n";

let dayPricesChart;

let today = new Date();

const monthTillToday = "1 month till today";

export default {
  name: "portfolio-view",
  components: {
    News,
    RouteBreadCrumb,
    Portfolio,
    CalendarWidget,
    Holding,

    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  watch: {
    $route(to) {
      if (to.name === "portfolio" && to.params.id) {
        this.fetchData(to.params.id);
      }
    },
  },
  data() {
    return {
      dayPricesID: "day-prices",

      portfolio: {
        id: "",
        name: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },

      secs: [],
      secsPagesCursors: {},
      secsCurrentPage: 1,

      bigLineChart: {
        allData: [[0, 20, 10, 30, 15, 40, 20, 60, 60]],
        activeIndex: 0,
      },

      news: [],
      newsPagesCursors: {},
      newsCurrentPage: 1,

      earnings: [],
      dividends: [],
      prices: [],
      periods: [],

      pricesPeriod: {
        from: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        ),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      },

      selectedPeriod: monthTillToday,
    };
  },
  computed: {
    totalNewsPages() {
      if (this.newsPagesCursors[this.newsCurrentPage] === 0) {
        return this.newsCurrentPage;
      }

      return this.newsCurrentPage + 1;
    },
    totalSecsPages() {
      if (this.secsPagesCursors[this.secsCurrentPage] === 0) {
        return this.secsCurrentPage;
      }

      return this.secsCurrentPage + 1;
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  created() {
    this.fetchData(this.$route.params.id);
  },
  mounted() {
    dayPricesChart = new Chart(
      document.getElementById(this.dayPricesID).getContext("2d"),
      {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "transparent",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 0,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      }
    );
  },
  methods: {
    async fetchData(id) {
      let err = await this.$store
        .dispatch("getPortfolio", id)
        .then(({ data }) => {
          this.portfolio = data;
        })
        .catch((err) => {
          if (err.message && err.message.search("404") !== -1) {
            this.$router.push({
              name: "NotFound",
            });

            return err;
          }

          this.processError(err);

          return err;
        });

      if (typeof err !== "undefined") {
        return;
      }

      this.updatePortfolioSecs(1);
      this.updateNews(1);
      this.updatePricesChart(id, this.pricesPeriod.from, this.pricesPeriod.to);

      this.$store
        .dispatch("getPortfolioDayPricesPeriods", { pid: id })
        .then(({ data }) => {
          this.periods = data;

          this.periods.unshift({
            date: monthTillToday,
          });
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateNews(currentPage) {
      this.newsCurrentPage = currentPage;

      this.$store
        .dispatch("getPortfolioNews", {
          pid: this.$route.params.id,
          cursor: this.newsPagesCursors[this.newsCurrentPage - 1],
        })
        .then(({ data, meta }) => {
          this.news = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.newsPagesCursors[this.newsCurrentPage] =
              meta.pagination.cursor.next;
          } else {
            this.newsPagesCursors[this.newsCurrentPage] = 0;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updatePortfolioSecs(currentPage) {
      this.secsCurrentPage = currentPage;

      this.$store
        .dispatch("getPortfolioSecurities", {
          pid: this.$route.params.id,
          cursor: this.secsPagesCursors[this.secsCurrentPage - 1],
          size: 12,
          sort: "id",
        })
        .then(({ data, meta }) => {
          this.secs = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.secsPagesCursors[this.secsCurrentPage] =
              meta.pagination.cursor.next;
          } else {
            this.secsPagesCursors[this.secsCurrentPage] = 0;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    deleteHolding(pid, sid) {
      this.secs.forEach((v, i) => {
        if (v.id === sid) {
          this.secs.splice(i, 1);
        }
      });
    },
    formatDate(date) {
      return formatDate(parseISO(date), "dd.MM.yyyy HH:mm");
    },
    formatChangePrice(val) {
      if (val === "") {
        return val;
      }

      return new Big(val).toFixed(2);
    },
    updateCalendarInterval(interval) {
      this.$store
        .dispatch("getPortfolioEarnings", {
          id: this.$route.params.id,
          from: formatDate(interval.from, "yyyy-MM-dd"),
          to: formatDate(interval.to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.earnings = data;
        })
        .catch((err) => {
          this.processError(err);
        });

      this.$store
        .dispatch("getPortfolioDividends", {
          id: this.$route.params.id,
          from: formatDate(interval.from, "yyyy-MM-dd"),
          to: formatDate(interval.to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.dividends = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    selectPricePeriod(date) {
      if (date === monthTillToday) {
        this.pricesPeriod = {
          from: new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate()
          ),
          to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        };
      } else {
        let d = new Date(date);

        this.pricesPeriod = {
          from: new Date(d.getFullYear(), d.getMonth(), 1),
          to: new Date(d.getFullYear(), d.getMonth() + 1, 0),
        };
      }

      this.updatePricesChart(
        this.$route.params.id,
        this.pricesPeriod.from,
        this.pricesPeriod.to
      );
    },
    updatePricesChart(id, from, to) {
      this.$store
        .dispatch("getPortfolioDayPrices", {
          pid: id,
          from: formatDate(from, "yyyy-MM-dd"),
          to: formatDate(to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.prices = data;

          let labels = [];
          let values = [];

          data.forEach((item) => {
            labels.push(item.date);
            values.push(item.price);
          });

          dayPricesChart.config.data.labels = labels;
          dayPricesChart.config.data.datasets = [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              borderColor: "#5e72e4",
              pointRadius: 2,
              backgroundColor: "transparent",
              data: values,
            },
          ];
          dayPricesChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    processError(err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 400)
      ) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
