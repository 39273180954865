<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-12 col-sm-6 col-xl-2">
          <el-select
            class="mb-5"
            :placeholder="t('Market')"
            v-model="country_id"
            filterable
            @change="changeFilter"
            clearable
          >
            <el-option
              v-for="option in countries"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-12 col-sm-6 col-xl-2">
          <el-select
            class="mb-5"
            :placeholder="t('Currency')"
            v-model="currency_id"
            filterable
            @change="changeFilter"
            clearable
          >
            <el-option
              v-for="option in currencies"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-12 col-sm-6 col-xl-2">
          <el-select
            class="mb-5"
            :placeholder="t('Sector')"
            v-model="sector_id"
            filterable
            @change="changeFilter"
            clearable
          >
            <el-option
              v-for="option in sectors"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-12 col-sm-6 col-xl-2">
          <el-select
            class="mb-5"
            :placeholder="t('Industry')"
            v-model="industry_id"
            filterable
            @change="changeFilter"
            clearable
          >
            <el-option
              v-for="option in industries"
              class="select-danger"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="col-12 col-sm-6 col-xl-2">
          <el-select
            class="mb-5"
            :placeholder="t('Exchange')"
            v-model="exchange_id"
            filterable
            @change="changeFilter"
            clearable
          >
            <el-option
              v-for="option in exchanges"
              class="select-danger"
              :value="option.id"
              :label="option.name + ' (' + option.code + ')'"
              :key="option.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-sm-6 col-xl-4"
          :key="security.id"
          v-for="security in secs"
        >
          <security
            :security="security"
            @open-add-to-portfolio-modal="openAddToPortfolioModal"
          ></security>
        </div>
      </div>
      <div class="row">
        <base-pagination
          v-model="currentPage"
          :page-count="totalPages"
          @update:modelValue="updateCurrentPage"
        ></base-pagination>
      </div>
    </div>
  </div>
  <div>
    <add-security ref="modal"></add-security>
  </div>
</template>

<script>
import RouteBreadCrumb from "../../components/Breadcrumb/RouteBreadcrumb";
import Security from "./Security";
import AddSecurity from "../Portfolios/AddSecurity";
import { useToast } from "vue-toastification";
import Notification from "../../components/Notification";
import { ElOption, ElSelect } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  name: "Screener",
  components: {
    RouteBreadCrumb,
    Security,
    AddSecurity,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "Screener") {
          document.title =
            this.t("Screener") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  computed: {
    totalPages() {
      if (this.pagesCursors[this.currentPage] === 0) {
        return this.currentPage;
      }

      return this.currentPage + 1;
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  data() {
    return {
      countries: [],
      country_id: "236",

      currencies: [],
      currency_id: "",

      sectors: [],
      sector_id: "",

      industries: [],
      industry_id: "",

      exchanges: [],
      exchange_id: "",

      secs: [],

      pagesCursors: [],
      currentPage: 1,
    };
  },
  created() {
    if (!this.$store.getters.parsedToken) {
      return;
    }

    this.updateCurrentPage(1, this.country_id);
    this.getCountries();
    this.getCurrencies();
    this.getExchanges();
    this.getSectors();
    this.getIndustries();
  },
  methods: {
    openAddToPortfolioModal(sid) {
      this.$refs.modal.openAddToPortfolioModal(sid);
    },
    updateCurrentPage(currentPage) {
      this.currentPage = currentPage;

      this.getSecs(
        currentPage,
        this.country_id,
        this.exchange_id,
        this.currency_id,
        this.sector_id,
        this.industry_id
      );
    },
    getSecs(
      currentPage,
      country_id,
      exchange_id,
      currency_id,
      sector_id,
      industry_id
    ) {
      this.$store
        .dispatch("getSecurities", {
          cursor: this.pagesCursors[this.currentPage - 1],
          pageSize: 18,
          sort: "id",
          country_id: country_id,
          exchange_id: exchange_id,
          currency_id: currency_id,
          sector_id: sector_id,
          industry_id: industry_id,
        })
        .then(({ data, meta }) => {
          this.secs = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.pagesCursors[this.currentPage] = meta.pagination.cursor.next;
          } else {
            this.pagesCursors[this.currentPage] = 0;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getCountries() {
      this.$store
        .dispatch("getCountries")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getCurrencies() {
      this.$store
        .dispatch("getCurrencies")
        .then(({ data }) => {
          this.currencies = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getExchanges() {
      this.$store
        .dispatch("getExchanges")
        .then(({ data }) => {
          this.exchanges = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getSectors() {
      this.$store
        .dispatch("getSectors")
        .then(({ data }) => {
          this.sectors = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getIndustries() {
      this.$store
        .dispatch("getIndustries")
        .then(({ data }) => {
          this.industries = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    changeFilter(id) {
      this.updateCurrentPage(1, id);
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
};
</script>

<style scoped></style>
