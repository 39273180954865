<template>
  <card class="card-stats" v-if="security.stock !== null">
    <div class="row">
      <div class="col">
        <h5 class="card-title text-uppercase text-muted mb-0">
          <router-link
            :to="{
              name: 'Security',
              params: { id: security.id, lang: $route.params.lang },
            }"
            >{{ security.stock.name }} ({{ security.stock.ticker }})
          </router-link>
        </h5>
        <span class="h2 font-weight-bold mb-0">{{
          security.quote
            ? formatPrice(security.quote.close) + " " + security.currency.name
            : ""
        }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="mt-3 mb-0 text-sm">
          <span
            class="text-success mr-2"
            v-if="
              security.quote &&
              security.quote.percent_change !== '' &&
              security.quote.percent_change.search('\-') !== 0
            "
            ><i class="fa fa-arrow-up"></i>
            {{ formatChangePrice(security.quote.percent_change) }}%</span
          >

          <span
            class="text-warning mr-2"
            v-if="
              security.quote &&
              security.quote.percent_change !== '' &&
              security.quote.percent_change.search('\-') === 0
            "
            ><i class="fa fa-arrow-down"></i>
            {{ formatChangePrice(security.quote.percent_change) }}%
          </span>

          <span v-if="security.quote && security.quote.percent_change !== ''">{{
            t("1d")
          }}</span>
        </div>
      </div>
      <div class="col">
        <div class="mt-3 mb-0 text-sm" v-if="security.portfolioSecurity">
          {{ security.portfolioSecurity.amount }} {{ t("shares") }}
        </div>
      </div>
      <div class="col">
        <div class="mt-3 mb-0 text-right">
          <router-link
            :to="{
              name: 'Transactions',
              params: {
                id: $route.params.id,
                sid: security.id,
                lang: $route.params.lang,
              },
            }"
            class="btn btn-neutral btn-sm"
            ><i class="fa fa-list"></i>
          </router-link>
          <span
            class="btn btn-neutral btn-sm"
            @click.prevent="deleteHoldings($route.params.id, security.id)"
            ><i class="fa fa-trash text-danger"></i>
          </span>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Big from "big.js";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import { useI18n } from "vue-i18n";

export default {
  name: "Holding",
  emits: ["deleted"],
  props: {
    security: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    formatChangePrice(val) {
      if (val === "") {
        return val;
      }

      return new Big(val).toFixed(2);
    },
    formatPrice(val) {
      if (val === "") {
        return val;
      }

      let bigVal = new Big(val);

      if (bigVal.cmp(new Big(1)) === 1) {
        return bigVal.toFixed(2);
      }

      return val;
    },
    async deleteHoldings(pid, sid) {
      try {
        await this.$store.dispatch("deleteFromPortfolio", {
          pid: pid,
          sid: sid,
        });

        this.runToast(
          "top-right",
          "success",
          "Holdings deleted from portfolio",
          "ni ni-bold-down"
        );

        this.$emit("deleted", pid, sid);
      } catch (err) {
        this.processError(err);
      }
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
