<template>
  <div class="container-fluid bg-danger">
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <h2 class="text-white">
            {{ t("Email not confirmed. Please") }}
            <router-link
              :to="{
                name: 'Profile',
                params: { lang: $route.params.lang },
              }"
            >
              <u>{{ t("confirm your email") }}</u></router-link
            >
            {{ t("to keep your account safe.") }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
};
</script>
