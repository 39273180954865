<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'ni ni-shop text-primary',
            path: {
              name: 'Dashboard root',
              params: { lang: $route.params.lang },
            },
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Portfolios',
            icon: 'ni ni-briefcase-24 text-primary',
            path: {
              name: 'Portfolios root',
              params: { lang: $route.params.lang },
            },
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Events',
            icon: 'ni ni-calendar-grid-58 text-red',
            path: {
              name: 'Calendar',
              params: { lang: $route.params.lang },
            },
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Plans',
            icon: 'ni ni-diamond text-info',
            path: {
              name: 'Plans',
              params: { lang: $route.params.lang },
            },
          }"
        >
        </sidebar-item>
      </template>

      <template v-slot:links-stocks>
        <sidebar-item
          :link="{
            name: 'Screener',
            icon: 'ni ni-atom',
            path: {
              name: 'Screener root',
              params: { lang: $route.params.lang },
            },
          }"
        >
        </sidebar-item>

        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Bonds',-->
        <!--            icon: 'ni ni-box-2',-->
        <!--            path: '/screener',-->
        <!--          }"-->
        <!--        >-->
        <!--        </sidebar-item>-->

        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: 'Funds',-->
        <!--            icon: 'ni ni-books',-->
        <!--            path: '/screener',-->
        <!--          }"-->
        <!--        >-->
        <!--        </sidebar-item>-->
      </template>

      <template v-slot:links-after>
        <hr class="my-3" />

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="mailto:support@stockwayup.com">
              <i class="ni ni-support-16"></i>
              <span class="nav-link-text">{{ t("Support") }}</span>
            </a>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              class="nav-link"-->
          <!--              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"-->
          <!--              target="_blank"-->
          <!--            >-->
          <!--              <i class="ni ni-spaceship"></i>-->
          <!--              <span class="nav-link-text">FAQ</span>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              class="nav-link"-->
          <!--              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html"-->
          <!--              target="_blank"-->
          <!--            >-->
          <!--              <i class="ni ni-palette"></i>-->
          <!--              <span class="nav-link-text">About us</span>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-email-confirmation
        v-if="$store.getters.parsedToken && !$store.getters.isEmailConfirmed"
      ></dashboard-email-confirmation>
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import DashboardNavbar from "./DashboardNavbar.vue";
import DashboardEmailConfirmation from "./DashboardEmailConfirmation.vue";
import ContentFooter from "./ContentFooter.vue";
import { useI18n } from "vue-i18n";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardEmailConfirmation,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to) {
        if (this.$route.params.lang === "en") {
          this.locale = "en";
          this.$router.replace({ name: to.name });
        } else if (this.$route.params.lang === "") {
          this.locale = "en";
        } else if (this.$route.params.lang === "ru") {
          this.locale = "ru";
        }

        document.title = this.t(
          "Stockwayup - progressive securities scoring service and portfolio tracker"
        );
      },
    },
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
