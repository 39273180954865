<template>
  <card
    header-classes="bg-transparent"
    :class="dark ? 'bg-transparent shadow-none' : ''"
  >
    <template v-slot:header v-if="!disableHeader">
      <h3 class="mb-0">{{ t("News") }}</h3>
    </template>

    <div v-if="news.length === 0">
      <div v-if="$route.name === 'Portfolio'">
        <router-link
          :to="{
            name: 'Screener root',
            params: { lang: $route.params.lang },
          }"
          >{{ t("Add securities") }}</router-link
        >
        {{ t("to portfolio to see news") }}
      </div>
      <div v-else-if="$route.name !== 'Security' && $route.name !== 'Index'">
        <router-link
          :to="{
            name: 'Create portfolio',
            params: { lang: $route.params.lang },
          }"
        >
          {{ t("Create portfolio") }}</router-link
        >
        {{ t("and") }}
        <router-link
          :to="{
            name: 'Screener root',
            params: { lang: $route.params.lang },
          }"
          >{{ t("add securities") }}</router-link
        >
        {{ t("to portfolio to see news") }}
      </div>
      <div v-else-if="$route.name !== 'Index'">
        {{ t("No news found for this security") }}
      </div>
    </div>

    <time-line type="one-side">
      <transition-group name="list" tag="div">
        <time-line-item
          v-for="newsItem in news"
          :key="newsItem.id"
          :badge-type="getNewsIconBadgeType(newsItem)"
          :badge-icon="getNewsIconType(newsItem)"
          class="list-item"
        >
          <small class="text-muted font-weight-bold">{{
            formatDate(newsItem.date)
          }}</small>
          <h5 class="mt-3 mb-0" :class="dark ? 'text-white' : ''">
            <a
              v-if="newsItem.url !== ''"
              :href="newsItem.url"
              target="_blank"
              rel="nofollow"
              >{{ newsItem.name }}</a
            >
            <template v-else>{{ newsItem.name }}</template>
          </h5>
          <p class="text-sm mt-1" :class="dark ? 'text-light' : ''">
            {{ newsItem.description }}
          </p>
          <p class="blockquote-footer" v-if="newsItem.newsSource">
            {{ newsItem.newsSource.name }}
          </p>
          <div class="mt-3">
            <template v-for="sec in newsItem.securities" :key="sec.id">
              <badge
                tag="a"
                :href="
                  $router.resolve({
                    name: 'Security',
                    params: { id: sec.id },
                  }).href
                "
                rounded
                type="default"
                >{{ sec.stock.ticker }}
              </badge>
            </template>
            <template
              v-for="newsCategory in newsItem.newsCategories"
              :key="newsCategory.id"
            >
              <badge rounded type="success">{{ newsCategory.name }}</badge>
              &nbsp;
            </template>
          </div>
        </time-line-item>
      </transition-group>
    </time-line>

    <template v-slot:footer v-if="totalPages > 1">
      <base-pagination
        v-model="page"
        :page-count="totalPages"
        @update:modelValue="updateCurrentPage"
        class="justify-content-end"
      ></base-pagination>
    </template>
  </card>
</template>

<script>
import TimeLine from "@/components/Timeline/TimeLine";
import TimeLineItem from "@/components/Timeline/TimeLineItem";
import { format as formatDate, parseISO } from "date-fns";
import { useI18n } from "vue-i18n";

export default {
  name: "news",
  components: {
    TimeLine,
    TimeLineItem,
  },
  props: {
    news: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  mounted() {
    this.page = this.currentPage;
  },
  methods: {
    formatDate(date) {
      return formatDate(parseISO(date), "dd.MM.yyyy HH:mm");
    },
    getNewsIconBadgeType(newsItem) {
      if (newsItem.sentiment === 1) {
        return "success";
      } else if (newsItem.sentiment === -1) {
        return "danger";
      }

      return "info";
    },
    getNewsIconType(newsItem) {
      if (newsItem.sentiment === 1) {
        return "ni ni-spaceship";
      } else if (newsItem.sentiment === -1) {
        return "ni ni-notification-70";
      }

      return "ni ni-bell-55";
    },
    updateCurrentPage(page) {
      this.page = page;

      this.$emit("updateCurrentPage", page);
    },
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(130px);
}
</style>
