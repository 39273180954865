<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridMonth')"
          >
            {{ t("Month") }}
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridWeek')"
          >
            {{ t("Week") }}
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">{{ t("Events calendar") }}</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <div id="fullCalendar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridDay from "@fullcalendar/timegrid";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Tooltip from "tooltip.js";
import Notification from "@/components/Notification";
import { format as formatDate } from "date-fns";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

let calendar;

export default {
  name: "calendar",
  components: {
    RouteBreadCrumb,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "Calendar") {
          document.title =
            this.t("Events calendar") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  data() {
    return {};
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t };
  },
  methods: {
    initCalendar() {
      let calendarEl = document.getElementById("fullCalendar");
      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridDay],
        initialView: "dayGridMonth",
        views: {
          dayGridMonth: {
            dayMaxEventRows: 2,
          },
        },
        eventDidMount: function (info) {
          new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            placement: "top",
            trigger: "hover",
            html: true,
            innerSelector: ".el-popper",
            template: '<div class="el-popover el-popper" role="tooltip"></div>',
          });
        },
        fixedWeekCount: true,
        selectable: false,
        headerToolbar: false,
        contentHeight: "auto",
        buttonIcons: {
          prev: " ni ni-bold-left",
          next: " ni ni-bold-right",
        },
        dayCellClassNames: "text-nowrap",
        editable: false,
        events: {},
      });

      calendar.render();

      this.$store
        .dispatch("getPortfoliosEarnings", {
          uid: this.$store.getters.parsedToken.uid,
          from: formatDate(calendar.view.activeStart, "yyyy-MM-dd"),
          to: formatDate(calendar.view.activeEnd, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.updateEarnings(data);
        })
        .catch((err) => {
          this.processError(err);
        });

      this.$store
        .dispatch("getPortfoliosDividends", {
          uid: this.$store.getters.parsedToken.uid,
          from: formatDate(calendar.view.activeStart, "yyyy-MM-dd"),
          to: formatDate(calendar.view.activeEnd, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.updateDividends(data);
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateEarnings(earnings) {
      earnings.forEach((model) => {
        let description =
          "<div class='el-popover__title'>" +
          model.stock.name +
          "</div>" +
          "<div><strong>" +
          this.t("Type") +
          ":</strong> " +
          this.t("earning release") +
          "</div>" +
          "<div><strong>" +
          this.t("Time") +
          ":</strong> " +
          this.t(model.time) +
          "</div>";

        if (model.eps_actual) {
          description +=
            "<div>" + this.t("EPS actual") + ": " + model.eps_actual + "</div>";
        }

        if (model.eps_estimate) {
          description +=
            "<div><strong>" +
            this.t("EPS estimate") +
            ":</strong> " +
            model.eps_estimate +
            "</div>";
        }

        if (model.difference) {
          description +=
            "<div>" + this.t("Difference") + ": " + model.difference + "</div>";
        }

        if (model.surprise_prc) {
          description +=
            "<div>" +
            this.t("Surprise prc") +
            ": " +
            model.surprise_prc +
            "</div>";
        }

        calendar.addEvent({
          id: model.id,
          title: model.stock.ticker,
          description: description,
          start: Date.parse(model.date),
          className: "event-default",
          allDay: true,
        });
      });
    },
    updateDividends(dividends) {
      dividends.forEach((model) => {
        let description =
          "<div class='el-popover__title'>" +
          model.stock.name +
          "</div>" +
          "<div><strong>" +
          this.t("Type") +
          ":</strong> " +
          this.t("dividend payment") +
          " </div>" +
          "<div><strong>" +
          this.t("Amount") +
          ":</strong> " +
          model.amount +
          "</div>" +
          "<div><strong>" +
          this.t("Currency") +
          ":</strong> " +
          model.currency +
          "</div>";

        calendar.addEvent({
          id: model.id,
          title: model.stock.ticker,
          description: description,
          start: Date.parse(model.date),
          className: "bg-success",
          allDay: true,
        });
      });
    },
    changeView(newView) {
      calendar.changeView(newView);
      calendar.view.title;
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
  mounted() {
    if (this.$store.getters.parsedToken) {
      this.initCalendar();
    }
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/core/vendors/fullcalendar";
</style>
