<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4 col-md-12">
          <card>
            <div class="row">
              <div class="col col-md-8" v-if="relation">
                <h5 class="h2 font-weight-bold mb-0">
                  {{ relation.name }}
                </h5>
                <span class="text-sm text-muted">{{ relation.ticker }}</span>
              </div>
              <div class="col col-md-4">
                <span class="h3 font-weight-bold mb-0" v-if="security.quote"
                  ><price :value="security.quote.close"></price>
                  {{ security.currency.name }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col col-md-8">
                <p class="mt-3 mb-0 text-sm">
                  <base-button
                    type="primary"
                    outline
                    @click="openAddToPortfolioModal"
                    tag="a"
                    size="sm"
                  >
                    {{ t("Add to") }} <i class="ni ni-briefcase-24"></i>
                  </base-button>
                </p>
              </div>
              <div class="col col-md-4">
                <p class="mt-3 mb-0 text-sm">
                  <span
                    class="text-success mr-2"
                    v-if="
                      security.quote &&
                      security.quote.percent_change.search('\-') !== 0
                    "
                    ><i class="fa fa-arrow-up"></i>&nbsp;<change-price
                      :value="security.quote.percent_change"
                    ></change-price>
                    %</span
                  >

                  <span
                    class="text-warning mr-2"
                    v-if="
                      security.quote &&
                      security.quote.percent_change.search('\-') === 0
                    "
                    ><i class="fa fa-arrow-down"></i>&nbsp;<change-price
                      :value="security.quote.percent_change"
                    ></change-price
                    >%</span
                  >
                  <span
                    class="text-nowrap"
                    v-if="security.quote && security.quote.percent_change"
                    >{{ t("1d") }}</span
                  >
                </p>
              </div>
            </div>
            <div>
              <add-security ref="modal"></add-security>
            </div>
          </card>
        </div>
        <div class="col-xl-7 col-md-12 text-white" v-if="security.company">
          <p>
            {{ security.company.description }}
          </p>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <!--                  <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>-->
                  <h5 class="h3 text-white mb-0">
                    {{ t("Price history") }}
                  </h5>
                </div>
                <div class="col">
                  <el-select
                    class="dropdown float-right"
                    :placeholder="t('Period')"
                    v-model="selectedPeriod"
                    filterable
                    @change="selectPricePeriod"
                  >
                    <el-option
                      v-for="period in periods"
                      :value="period.date"
                      :label="
                        period.date === '1 month till today'
                          ? t(period.date)
                          : period.date
                      "
                      :key="period.date"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="450" :id="dayPricesID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5">
          <div class="row">
            <div class="col-xl-12">
              <news :news="news"></news>
            </div>
          </div>
        </div>

        <div class="col-xl-7">
          <!--          <div class="row">-->
          <!--            <div class="col-md-12">-->
          <!--              <card gradient="default" no-body="">-->
          <!--                <div class="card-body">-->
          <!--                  <div class="mb-2">-->
          <!--                    <sup class="text-white">Valuation</sup>-->
          <!--                    <div>-->
          <!--                      <span class="text-success font-weight-600"-->
          <!--                        >2% Overvalued</span-->
          <!--                      >-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->

          <!--                <div class="card-body">-->
          <!--                  <div class="row">-->
          <!--                    <div class="col">-->
          <!--                      <base-progress :value="60" :height="6" type="success" />-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </card>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-md-12">-->
          <!--              <card gradient="default" no-body="">-->
          <!--                <div class="card-body">-->
          <!--                  <div class="mb-2">-->
          <!--                    <sup class="text-white">Valuation</sup>-->
          <!--                    <div>-->
          <!--                      <span class="text-warning font-weight-600"-->
          <!--                        >20% Overvalued</span-->
          <!--                      >-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->

          <!--                <div class="card-body">-->
          <!--                  <div class="row">-->
          <!--                    <div class="col">-->
          <!--                      <base-progress :value="60" :height="6" type="success" />-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </card>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="row">
            <div class="col-md-12">
              <card header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">{{ t("Income statement") }}</h5>
                    </div>
                    <div class="col">
                      <base-dropdown
                        title-classes="btn btn-secondary mr-0"
                        class="float-right"
                      >
                        <template v-slot:title>
                          {{ t(incomeStatementPeriod) }}
                        </template>

                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="
                            selectIncomeStatementPeriod('quarterly')
                          "
                          >{{ t("Quarterly") }}</a
                        >
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="selectIncomeStatementPeriod('annual')"
                          >{{ t("Annual") }}</a
                        >
                      </base-dropdown>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="450" :id="incomeStatementID"></canvas>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <card class="bg-default" header-classes="bg-default">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="text-white mb-1">
                        {{ t("Balance position") }}
                      </h3>
                    </div>
                    <div class="col">
                      <base-dropdown
                        title-classes="btn btn-secondary mr-0"
                        class="float-right"
                      >
                        <template v-slot:title>
                          {{ t(balancePositionPeriod) }}
                        </template>

                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="
                            selectBalancePositionPeriod('quarterly')
                          "
                          >{{ t("Quarterly") }}</a
                        >
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="selectBalancePositionPeriod('annual')"
                          >{{ t("Annual") }}</a
                        >
                      </base-dropdown>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="350" :id="balancePositionID"></canvas>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <card header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="mb-1">{{ t("Debt to equity history") }}</h5>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="350" :id="debtToEquityHistoryID"></canvas>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <card header-classes="bg-transparent">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="mb-1">{{ t("Balance sheet") }}</h5>
                    </div>
                  </div>
                </template>
                <div class="chart-area">
                  <canvas :height="550" :id="balanceSheetID"></canvas>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { format as formatDate, parseISO } from "date-fns";
import News from "@/views/Widgets/News";
import AddSecurity from "../Portfolios/AddSecurity";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Chart from "chart.js";
import { ElSelect, ElOption } from "element-plus";
import Price from "../Widgets/Price";
import ChangePrice from "../Widgets/ChangePrice";
import { useI18n } from "vue-i18n";

let today = new Date();

const monthTillToday = "1 month till today";

let dayPricesChart;
let incomeStatementChart;
let balancePositionChart;
let debtToEquityHistoryChart;
let balanceSheetChart;

export default {
  name: "security-view",
  components: {
    AddSecurity,
    News,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Price,
    ChangePrice,
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        this.fetchData(to.params.id);
      }
    },
    debtToEquityHistory(to) {
      if (to.length > 0) {
        this.updateBalanceSheetChart(to[to.length - 1]);
      } else {
        this.updateBalanceSheetChart([]);
      }
    },
  },
  computed: {
    relation: function () {
      if (this.security.stock !== undefined) {
        return this.security.stock;
      }

      if (this.security.bond !== undefined) {
        return this.security.bond;
      }

      if (this.security.fund !== undefined) {
        return this.security.fund;
      }

      return null;
    },
  },
  data() {
    return {
      dayPricesID: "portfoliosSummary",
      balancePositionID: "balancePosition",
      balanceSheetID: "balanceSheet",
      debtToEquityHistoryID: "debtToEquityHistory",
      incomeStatementID: "incomeStatementID",
      security: {
        id: "",
        bond: null,
        fund: null,
        stock: null,
        quote: null,
        company: null,
        exchange: null,
      },
      news: [],
      prices: [],
      periods: [],
      pricesPeriod: {
        from: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        ),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      },
      isShowAddToPortfolioModal: false,
      balancePositionPeriod: "Quarterly",
      incomeStatementPeriod: "Quarterly",
      debtToEquityHistory: [],
      balancePosition: [],
      incomeStatement: [],

      selectedPeriod: monthTillToday,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  created() {
    this.fetchData(this.$route.params.id);
  },
  mounted() {
    this.initDayPricesChart();
    this.initIncomeStatementChart();
    this.initBalancePositionChart();
    this.initDebtToEquityHistoryChart();
    this.initBalanceSheetChart();
  },
  methods: {
    openAddToPortfolioModal() {
      this.$refs.modal.openAddToPortfolioModal(this.security.id);
    },
    async fetchData(id) {
      let err = await this.$store
        .dispatch("getSecurity", id)
        .then(({ data }) => {
          this.security = data;
        })
        .catch((err) => {
          if (err.message && err.message.search("404") !== -1) {
            this.$router.push({
              name: "NotFound",
            });

            return err;
          }

          this.processError(err);

          return err;
        });

      if (typeof err !== "undefined") {
        return;
      }

      this.$store
        .dispatch("getSecurityNews", id)
        .then(({ data }) => {
          this.news = data;
        })
        .catch((err) => {
          this.processError(err);
        });

      this.updatePricesChart(id, this.pricesPeriod.from, this.pricesPeriod.to);
      this.updateBalancePositionChart(id, "quarterly");
      this.updateDebtToEquityHistoryChart(id, "quarterly");
      this.updateIncomeStatementChart(id, "quarterly");

      this.$store
        .dispatch("getSecDayPricesPeriods", { sid: id })
        .then(({ data }) => {
          this.periods = data;

          this.periods.unshift({
            date: monthTillToday,
          });
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    formatDate(date) {
      return formatDate(parseISO(date), "dd.MM.yyyy HH:mm");
    },
    selectPricePeriod(date) {
      if (date === monthTillToday) {
        this.pricesPeriod = {
          from: new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate()
          ),
          to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        };
      } else {
        let d = new Date(date);

        this.pricesPeriod = {
          from: new Date(d.getFullYear(), d.getMonth(), 1),
          to: new Date(d.getFullYear(), d.getMonth() + 1, 0),
        };
      }

      this.updatePricesChart(
        this.$route.params.id,
        this.pricesPeriod.from,
        this.pricesPeriod.to
      );
    },
    selectBalancePositionPeriod(period) {
      this.updateBalancePositionChart(this.$route.params.id, period);
    },
    selectIncomeStatementPeriod(period) {
      this.updateIncomeStatementChart(this.$route.params.id, period);
    },
    updatePricesChart(id, from, to) {
      this.$store
        .dispatch("getSecDayPrices", {
          sid: id,
          from: formatDate(from, "yyyy-MM-dd"),
          to: formatDate(to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.prices = data;

          let labels = [];
          let values = [];

          data.forEach((item) => {
            labels.push(item.date);
            values.push(item.close);
          });

          dayPricesChart.config.data.labels = labels;
          dayPricesChart.config.data.datasets = [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              borderColor: "#5e72e4",
              pointRadius: 2,
              backgroundColor: "transparent",
              data: values,
            },
          ];
          dayPricesChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateBalancePositionChart(id, period) {
      let method = "getQuarterlyBalanceSheet";

      if (period === "annual") {
        method = "getAnnualBalanceSheet";
        this.balancePositionPeriod = "Annual";
      } else {
        this.balancePositionPeriod = "Quarterly";
      }

      this.$store
        .dispatch(method, {
          sid: id,
          size: 20,
        })
        .then(({ data }) => {
          this.balancePosition = data;

          let labels = [];
          let totalCurrentAssets = [];
          let totalNonCurrentAssets = [];
          let totalCurrentLiabilities = [];
          let totalNonCurrentLiabilities = [];

          data.forEach((item) => {
            labels.push(item.fiscal_date);
            totalCurrentAssets.push(item.current_assets_total_current_assets);
            totalNonCurrentAssets.push(
              item.non_current_assets_total_non_current_assets
            );
            totalCurrentLiabilities.push(
              item.current_liabilities_total_current_liabilities
            );
            totalNonCurrentLiabilities.push(
              item.non_current_liabilities_total_non_current_liabilities
            );
          });

          balancePositionChart.config.data.labels = labels.reverse();
          balancePositionChart.config.data.datasets = [
            {
              label: "Total current assets",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              backgroundColor: "#fb6340",
              data: totalCurrentAssets.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "Total current liabilities",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              backgroundColor: "#5e72e4",
              data: totalCurrentLiabilities.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "Total non current liabilities",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              backgroundColor: "#f87979",
              data: totalNonCurrentAssets.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "Total non current liabilities",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              backgroundColor: "#f4f5f7",
              data: totalNonCurrentLiabilities.reverse(),
              maxBarThickness: 10,
            },
          ];
          balancePositionChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateDebtToEquityHistoryChart(id) {
      this.$store
        .dispatch("getQuarterlyBalanceSheet", {
          sid: id,
          size: 20,
        })
        .then(({ data }) => {
          this.debtToEquityHistory = data;

          let labels = [];
          let totalAssets = [];
          let totalLiabilities = [];
          let cash = [];

          data.forEach((item) => {
            labels.push(item.fiscal_date);
            totalAssets.push(item.total_assets);
            totalLiabilities.push(item.total_liabilities);
            cash.push(item.cash);
            cash.push(item.cash_equivalents);
          });

          debtToEquityHistoryChart.config.data.labels = labels.reverse();
          debtToEquityHistoryChart.config.data.datasets = [
            {
              label: "The sum of current and long-term assets values",
              tension: 0.4,
              borderWidth: 2,
              pointRadius: 0,
              backgroundColor: "rgba(251, 99, 64, 0.5)",
              data: totalAssets.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "The sum of current assets and non-current liabilities",
              tension: 0.4,
              borderWidth: 2,
              pointRadius: 0,
              backgroundColor: "rgba(94, 114, 228, 0.5)",
              data: totalLiabilities.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "Cash",
              tension: 0.4,
              borderWidth: 2,
              pointRadius: 0,
              backgroundColor: "rgba(255, 255, 0, 0.5)",
              data: cash.reverse(),
              maxBarThickness: 10,
            },
            {
              label: "Cash equivalents",
              tension: 0.4,
              borderWidth: 2,
              pointRadius: 0,
              backgroundColor: "rgba(229,140,248,0.0)",
              data: cash.reverse(),
              maxBarThickness: 10,
            },
          ];
          debtToEquityHistoryChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateBalanceSheetChart(data) {
      let dataset = [];
      let labels = [];
      let fields = {
        current_assets_cash:
          "Cash includes currency, bank accounts, and undeposited checks",
        current_assets_cash_equivalents:
          "Cash equivalents that have high credit quality and are highly liquid",
        current_assets_other_short_term_investments:
          "Other short term investments",
        current_assets_accounts_receivable:
          "The balance of money due to a firm for goods or services delivered or used but not yet paid for by customers",
        current_assets_other_receivables: "Other receivables",
        current_assets_inventory:
          "The goods available for sale and raw materials used to produce goods available for sale",
        current_assets_other_current_assets: "Other current assets",
        // current_assets_total_current_assets: "All current assets values in a total",
        non_current_assets_properties: "Property owned",
        non_current_assets_land_and_improvements: "Land and improvements owned",
        non_current_assets_machinery_furniture_equipment:
          "Office equipment, furniture, and vehicles owned",
        non_current_assets_leases: "Operating and financial leases",
        non_current_assets_accumulated_depreciation:
          "The cumulative depreciation of an asset that has been recorded",
        non_current_assets_goodwill:
          "The value of a brand name, solid customer base, good customer relations, good employee relations, and proprietary technology",
        non_current_assets_intangible_assets:
          "The patents, trademarks, and other intellectual properties",
        investments_and_advances: "Available for sale financial securities",
        non_current_assets_other_non_current_assets: "Other long-term assets",
        // non_current_assets_total_non_current_assets: "All long-term assets values in total",
        // total_assets:
        //   "The sum of total current assets + total non current assets",
        current_liabilities_accounts_payable:
          "Refers to an account within the general ledger that represents an obligation to pay off a short-term debt to creditors or suppliers",
        current_liabilities_accrued_expenses:
          "Payments that a company is obligated to pay in the future for which goods and services have already been delivered",
        current_liabilities_short_term_debt:
          "Current debt and capital lease obligations",
        current_liabilities_deferred_revenue:
          "Refers to advance payments a company receives for products or services that are to be delivered or performed in the future",
        current_liabilities_other_current_liabilities:
          "Other current liabilities",
        current_liabilities_total_current_liabilities: "Total current assets",
        non_current_liabilities_long_term_debt:
          "Amount of outstanding debt that has a maturity of 12 months or longer",
        non_current_liabilities_provision_for_risks_and_charges:
          "Funds set aside as assets to pay for anticipated future losses",
        non_current_liabilities_deferred_liabilities:
          "Revenue producing activity for which revenue has not yet been recognized, and is not expected to be recognized in the next 12 months",
        non_current_liabilities_other_non_current_liabilities:
          "Other non-current liabilities",
        non_current_liabilities_total_non_current_liabilities:
          "Total non-current liabilities",
        // total_liabilities:
        //   "The sum of total current liabilities + total non current liabilities",
        // shareholders_equity_common_stock:
        //   "Net worth of investors shares, which is equal to the total_assets - total_liabilities",
        // shareholders_equity_retained_earnings: "Refers to the profits earned minus dividends paid",
        // shareholders_equity_other_shareholders_equity:
        //   "Other not affecting retained earnings gains and looses",
        // shareholders_equity_total_shareholders_equity:
        //   "The net worth of a company, which is the amount that would be returned to shareholders if a company's total assets were liquidated, and all of its debts were repaid",
      };

      for (const key in fields) {
        if (!Object.prototype.hasOwnProperty.call(data, key)) {
          continue;
        }

        if (data[key] === 0) {
          continue;
        }

        labels.push(fields[key]);

        dataset.push(data[key]);
      }

      if (labels.length === 0) {
        labels.push(fields["current_assets_cash"]);
        labels.push(fields["current_assets_cash_equivalents"]);
        labels.push(fields["non_current_assets_properties"]);
        labels.push(fields["non_current_assets_other_non_current_assets"]);
      }

      balanceSheetChart.config.data.labels = labels;

      balanceSheetChart.config.data.datasets = [
        {
          label: "Dataset 1",
          data: dataset,
          backgroundColor: [
            "#f5365c",
            "#fb6340",
            "#2dce89",
            "#5e72e4",
            "#11cdef",
            "#00876c",
            "#439981",
            "#6aaa96",
            "#8cbcac",
            "#00876c",
            "#3d936b",
            "#609e6a",
            "#7ea96c",
            "#9cb470",
            "#babd77",
            "#d7c781",
            "#d8b36c",
            "#da9f5b",
            "#db8950",
            "#db724b",
            "#d9594c",
            "#d43d51",
            "#00876c",
            "#3d9c73",
            "#63b179",
            "#88c580",
            "#aed987",
            "#d6ec91",
            "#ffff9d",
            "#fee17e",
            "#fcc267",
            "#f7a258",
            "#ef8250",
            "#e4604e",
            "#d43d51",
          ],
          showLine: false,
          barPercentage: 1,
        },
      ];

      balanceSheetChart.update();
    },
    updateIncomeStatementChart(id, period) {
      let method = "getQuarterlyIncomeStatement";

      if (period === "annual") {
        method = "getAnnualIncomeStatement";
        this.incomeStatementPeriod = "Annual";
      } else {
        this.incomeStatementPeriod = "Quarterly";
      }

      this.$store
        .dispatch(method, {
          sid: id,
          size: 20,
        })
        .then(({ data }) => {
          this.incomeStatement = data;

          let datasets = [];
          let labels = [];
          let dates = {};
          let values = {};
          let fields = {
            basic_shares_outstanding:
              "Refers for the shares outstanding held by all its shareholders",
            cost_of_goods: "Refers to cost of revenue",
            diluted_shares_outstanding:
              "Refers to the total number of shares a company would have if all dilutive securities were exercised and converted into shares",
            ebitda:
              "Refers to EBITDA (earnings before interest, taxes, depreciation, and amortization) measure",
            eps_basic: "Refers to earnings per share (EPS)",
            eps_diluted: "Refers to diluted earnings per share (EPS)",
            gross_profit: "Refers to net gross profit: sales - cost of goods",
            income_tax: "Refers to a tax provision",
            non_operating_interest_expense:
              "Refers to non-operating interest expense",
            non_operating_interest_income:
              "Refers to non-operating interest income",
            operating_expense_research_and_development:
              "Refers to research & development (R&D) expenses",
            operating_expense_selling_general_and_administrative:
              "Refers to selling, general and administrative (SG&A) expenses",
            other_income_expense: "Refers to other incomes or expenses",
            net_income: "Refers to net income: pretax income - income tax",
          };
          let colors = [
            "#f5365c",
            "#fb6340",
            "#2dce89",
            "#5e72e4",
            "#11cdef",
            "#00876c",
            "#439981",
            "#6aaa96",
            "#8cbcac",
            "#00876c",
            "#3d936b",
            "#609e6a",
            "#7ea96c",
            "#9cb470",
            "#babd77",
            "#d7c781",
            "#d8b36c",
            "#da9f5b",
            "#db8950",
            "#db724b",
            "#d9594c",
            "#d43d51",
            "#00876c",
            "#3d9c73",
            "#63b179",
            "#88c580",
            "#aed987",
            "#d6ec91",
            "#ffff9d",
            "#fee17e",
            "#fcc267",
            "#f7a258",
            "#ef8250",
            "#e4604e",
            "#d43d51",
          ];

          for (const dkey in data) {
            for (const key in fields) {
              if (
                !Object.prototype.hasOwnProperty.call(
                  dates,
                  data[dkey].fiscal_date
                )
              ) {
                dates[data[dkey].fiscal_date] = true;
                labels.push(data[dkey].fiscal_date);
              }

              if (!Object.prototype.hasOwnProperty.call(values, key)) {
                values[key] = [];
              }

              if (data[dkey][key] === 0) {
                continue;
              }

              values[key].push(data[dkey][key]);
            }
          }

          for (const key in values) {
            if (values[key].length === labels.length) {
              datasets.push({
                label: fields[key],
                tension: 0,
                borderWidth: 4,
                borderColor: colors.shift(),
                pointRadius: 2,
                backgroundColor: "transparent",
                data: values[key].reverse(),
              });
            }
          }

          incomeStatementChart.config.data.labels = labels.reverse();
          incomeStatementChart.config.data.datasets = datasets;

          incomeStatementChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 400)
      ) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    initDayPricesChart() {
      dayPricesChart = new Chart(
        document.getElementById(this.dayPricesID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
    initIncomeStatementChart() {
      incomeStatementChart = new Chart(
        document.getElementById(this.incomeStatementID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "bottom",
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
    initBalancePositionChart() {
      balancePositionChart = new Chart(
        document.getElementById(this.balancePositionID).getContext("2d"),
        {
          type: "bar",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "bottom",
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    zeroLineColor: "#dee2e6",
                    drawBorder: false,
                    drawTicks: false,
                    lineWidth: 1,
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                  },
                  ticks: {
                    beginAtZero: true,
                    padding: 10,
                    fontSize: 13,
                    fontColor: "#8898aa",
                    fontFamily: "Open Sans",
                    callback: function (value) {
                      if (!(value % 10)) {
                        return value;
                      }
                    },
                  },
                },
              ],
              xAxes: [
                {
                  stacked: true,
                  gridLines: {
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                  },
                  ticks: {
                    padding: 20,
                    fontSize: 13,
                    fontColor: "#8898aa",
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
          },
        }
      );
    },
    initDebtToEquityHistoryChart() {
      debtToEquityHistoryChart = new Chart(
        document.getElementById(this.debtToEquityHistoryID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "bottom",
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
    initBalanceSheetChart() {
      balanceSheetChart = new Chart(
        document.getElementById(this.balanceSheetID).getContext("2d"),
        {
          type: "pie",
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 0,
            legend: {
              display: true,
              position: "bottom",
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              display: false,
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                    color: "transparent",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  },
};
</script>

<style scoped></style>
