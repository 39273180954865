"use strict";

import store from "../store";
import i18n from "../i18n";

export default function auth({ next }) {
  if (
    window["injectProperty"] !== undefined ||
    window["__PRERENDER_INJECTED"] !== undefined
  ) {
    return next();
  }

  if (!store.getters.isAuthenticated) {
    return next({ name: "Login", params: { lang: i18n.global.locale.value } });
  }

  if (store.getters.isTokenValid) {
    return next();
  }

  store
    .dispatch("refresh")
    .then(() => {
      return next();
    })
    .catch(() => {
      return next({
        name: "Login",
        params: { lang: i18n.global.locale.value },
      });
    });
}
