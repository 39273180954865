<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button size="sm" type="neutral">New</base-button>-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
        <portfolios></portfolios>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 text-white mb-0">
                    {{ t("Portfolios summary") }}
                  </h5>
                </div>
                <div class="col">
                  <el-select
                    class="dropdown float-right"
                    :placeholder="t('Period')"
                    v-model="selectedPeriod"
                    filterable
                    @change="selectPricePeriod"
                  >
                    <el-option
                      v-for="period in periods"
                      :value="period.date"
                      :label="
                        period.date == '1 month till today'
                          ? t(period.date)
                          : period.date
                      "
                      :key="period.date"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="395" :id="dayPricesID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-4">
          <calendar-widget
            :earnings="earnings"
            :dividends="dividends"
            @update-interval="updateCalendarInterval"
          ></calendar-widget>
        </div>
      </div>
      <!-- End charts-->

      <!--Widgets-->
      <div class="row">
        <div class="col-xl-5">
          <div class="row">
            <div class="col-xl-12">
              <news
                :news="news"
                :current-page="newsCurrentPage"
                :total-pages="totalNewsPages"
                @update-current-page="updateNews"
              ></news>
            </div>
          </div>
          <div class="row">
            <!--            <div class="col-xl-12">-->
            <!--              <card>-->
            <!--                <template v-slot:header>-->
            <!--                  <h5 class="h3 mb-0">Progress track</h5>-->
            <!--                </template>-->
            <!--                <progress-track-list></progress-track-list>-->
            <!--              </card>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="col-xl-7">
          <div class="row">
            <!--            <div class="col-md-6">-->
            <!--              <card header-classes="bg-transparent">-->
            <!--                <template v-slot:header>-->
            <!--                  <div class="row align-items-center">-->
            <!--                    <div class="col">-->
            <!--                      <h6 class="text-uppercase text-muted ls-1 mb-1">-->
            <!--                        Performance-->
            <!--                      </h6>-->
            <!--                      <h5 class="h3 mb-0">Total orders</h5>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--                <div class="chart-area">-->
            <!--                  <canvas :height="350" :id="ordersChartID"></canvas>-->
            <!--                </div>-->
            <!--              </card>-->
            <!--            </div>-->

            <!--            <div class="col-md-6">-->
            <!--              <card header-classes="bg-transparent">-->
            <!--                <template v-slot:header>-->
            <!--                  <div class="row align-items-center">-->
            <!--                    <div class="col">-->
            <!--                      <h6 class="text-muted text-uppercase ls-1 mb-1">-->
            <!--                        Overview-->
            <!--                      </h6>-->
            <!--                      <h5 class="h3 mb-0">Sales value</h5>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--                <div class="chart-area">-->
            <!--                  <canvas :height="350" :id="salesChartID2"></canvas>-->
            <!--                </div>-->
            <!--              </card>-->
            <!--            </div>-->
          </div>
          <div class="row">
            <div class="col-xl-12">
              <market-performance></market-performance>
              <!--              <div class="card bg-default shadow">-->
              <!--                <div class="card-header bg-transparent border-0">-->
              <!--                  <div class="row align-items-center">-->
              <!--                    <div class="col">-->
              <!--                      <h3 class="mb-0">Page visits</h3>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->

              <!--                <div class="row">-->
              <!--                  <div class="col-md-6"><dark-table></dark-table></div>-->
              <!--                  <div class="col-md-6"><dark-table></dark-table></div>-->
              <!--                </div>-->
              <!--                <div class="row">-->
              <!--                  <div class="col-md-6"><dark-table></dark-table></div>-->
              <!--                  <div class="col-md-6"><dark-table></dark-table></div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <!--End Widgets-->

      <!--Tables-->
      <div class="row">
        <div class="col-sm-12 col-md-5"></div>
        <div class="col-sm-12 col-md-7">
          <recently-viewed></recently-viewed>
        </div>
        <!--        <div class="col-xl-4">-->
        <!--          <social-traffic-table></social-traffic-table>-->
        <!--        </div>-->
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "../../components/Breadcrumb/RouteBreadcrumb";

// Lists
// import ProgressTrackList from "./ProgressTrackList";
// Tables
// import DarkTable from "@/views/Tables/RegularTables/DarkTable";
import RecentlyViewed from "./RecentlyViewed";
// import SocialTrafficTable from "./SocialTrafficTable";
// Charts
// import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js";
// Widgets
import CalendarWidget from "@/views/Widgets/CalendarWidget";
import News from "@/views/Widgets/News";

import Portfolios from "@/views/Dashboard/Portfolios";
import { format as formatDate } from "date-fns";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import MarketPerformance from "@/views/Dashboard/MarketPerformance";
import { ElSelect, ElOption } from "element-plus";
import { useI18n } from "vue-i18n";

let dayPricesChart;
let today = new Date();

const monthTillToday = "1 month till today";

export default {
  components: {
    // ProgressTrackList,
    RecentlyViewed,
    // SocialTrafficTable,
    // DarkTable,
    MarketPerformance,
    RouteBreadCrumb,
    CalendarWidget,
    Portfolios,
    News,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "Dashboard") {
          document.title =
            this.t("Dashboard") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  data() {
    return {
      dayPricesID: "day-prices",
      salesChartID2: "salesChart2",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },

      news: [],
      newsPagesCursors: {},
      newsCurrentPage: 1,

      earnings: [],
      dividends: [],
      prices: [],
      periods: [],

      pricesPeriod: {
        from: new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        ),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      },

      selectedPeriod: monthTillToday,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    initBigChart(index) {
      this.bigLineChart.activeIndex = index;
    },
    fetchData(id) {
      this.updatePricesChart(id, this.pricesPeriod.from, this.pricesPeriod.to);

      this.updateNews(1);

      this.$store
        .dispatch("getPortfoliosDayPricesPeriods", { uid: id })
        .then(({ data }) => {
          this.periods = data;

          this.periods.unshift({
            date: monthTillToday,
          });
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateNews(currentPage) {
      this.newsCurrentPage = currentPage;

      this.$store
        .dispatch("getPortfoliosNews", {
          uid: this.$store.getters.parsedToken.uid,
          cursor: this.newsPagesCursors[this.newsCurrentPage - 1],
        })
        .then(({ data, meta }) => {
          this.news = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.newsPagesCursors[this.newsCurrentPage] =
              meta.pagination.cursor.next;
          } else {
            this.newsPagesCursors[this.newsCurrentPage] = 0;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    updateCalendarInterval(interval) {
      this.$store
        .dispatch("getPortfoliosEarnings", {
          uid: this.$store.getters.parsedToken.uid,
          from: formatDate(interval.from, "yyyy-MM-dd"),
          to: formatDate(interval.to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.earnings = data;
        })
        .catch((err) => {
          this.processError(err);
        });

      this.$store
        .dispatch("getPortfoliosDividends", {
          uid: this.$store.getters.parsedToken.uid,
          from: formatDate(interval.from, "yyyy-MM-dd"),
          to: formatDate(interval.to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.dividends = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    selectPricePeriod(date) {
      if (date === monthTillToday) {
        this.pricesPeriod = {
          from: new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            today.getDate()
          ),
          to: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        };
      } else {
        let d = new Date(date);

        this.pricesPeriod = {
          from: new Date(d.getFullYear(), d.getMonth(), 1),
          to: new Date(d.getFullYear(), d.getMonth() + 1, 0),
        };
      }

      this.updatePricesChart(
        this.$store.getters.parsedToken.uid,
        this.pricesPeriod.from,
        this.pricesPeriod.to
      );
    },
    updatePricesChart(id, from, to) {
      this.$store
        .dispatch("getPortfoliosDayPrices", {
          uid: id,
          from: formatDate(from, "yyyy-MM-dd"),
          to: formatDate(to, "yyyy-MM-dd"),
        })
        .then(({ data }) => {
          this.prices = data;

          let labels = [];
          let values = [];

          data.forEach((item) => {
            labels.push(item.date);
            values.push(item.price);
          });

          dayPricesChart.config.data.labels = labels;
          dayPricesChart.config.data.datasets = [
            {
              label: "",
              tension: 0,
              borderWidth: 4,
              borderColor: "#5e72e4",
              pointRadius: 2,
              backgroundColor: "transparent",
              data: values,
            },
          ];

          dayPricesChart.update();
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
  computed: {
    totalNewsPages() {
      if (this.newsPagesCursors[this.newsCurrentPage] === 0) {
        return this.newsCurrentPage;
      }

      return this.newsCurrentPage + 1;
    },
  },
  created() {
    if (!this.$store.getters.parsedToken) {
      return;
    }

    this.fetchData(this.$store.getters.parsedToken.uid);
  },
  mounted() {
    dayPricesChart = new Chart(
      document.getElementById(this.dayPricesID).getContext("2d"),
      {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1,
                gridLines: {
                  drawBorder: false,
                  color: "transparent",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 0,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 10,
                  fontColor: "#8898aa",
                  fontSize: 13,
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      }
    );
  },
};
</script>

<style></style>
