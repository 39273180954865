import JsonApi from "devour-client";
import store from "./store";

const jsonApi = new JsonApi({
  apiUrl: process.env.VUE_APP_API_BASE_URL + "/api/v1",
});

jsonApi.define("session", {
  email: "",
  password: "",
  "access-token": "",
  "refresh-token": "",
});

jsonApi.define("user", {
  email: "",
  password: "",
  confirmation: "",
});

jsonApi.define("refresh-token", {
  "refresh-token": "",
});

jsonApi.define("currency", {
  name: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("sector", {
  name: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("industry", {
  name: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("company", {
  name: "",
  description: "",
  market_cap: "",
  pe_ratio: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("portfolio", {
  name: "",
  price: 0,
  month_change: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",

  securities: {
    jsonApi: "hasMany",
    type: "security",
  },
  currency: {
    jsonApi: "hasOne",
    type: "currency",
  },
});

jsonApi.define("security", {
  created_at: "",
  updated_at: "",
  deleted_at: "",
  stock: {
    jsonApi: "hasOne",
    type: "stocks",
  },
  bond: {
    jsonApi: "hasOne",
    type: "bonds",
  },
  fund: {
    jsonApi: "hasOne",
    type: "funds",
  },
  quote: {
    jsonApi: "hasOne",
    type: "quotes",
  },
  currency: {
    jsonApi: "hasOne",
    type: "currency",
  },
  company: {
    jsonApi: "hasOne",
    type: "company",
  },
  exchange: {
    jsonApi: "hasOne",
    type: "exchange",
  },
  portfolioSecurity: {
    jsonApi: "hasOne",
    type: "portfolio-securities",
  },
  quarterlyBalanceSheet: {
    jsonApi: "hasMany",
    type: "quarterly-balance-sheet",
  },
});

jsonApi.define("stock", {
  name: "",
  ticker: "",
  symbol: "",
  isin: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("bond", {
  name: "",
  ticker: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("fund", {
  name: "",
  ticker: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("quote", {
  average_volume: "",
  change: "",
  close: "",
  date: "",
  fifty_two_week_high: "",
  fifty_two_week_high_change: "",
  fifty_two_week_high_change_percent: "",
  fifty_two_week_low: "",
  fifty_two_week_low_change: "",
  fifty_two_week_low_change_percent: "",
  fifty_two_week_range: "",
  high: "",
  low: "",
  open: "",
  percent_change: "",
  previous_close: "",
  volume: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("earning", {
  date: "",
  time: "",
  eps_estimate: "",
  eps_actual: "",
  difference: "",
  surprise_prc: "",
  created_at: "",
  updated_at: "",

  stock: {
    jsonApi: "hasOne",
    type: "stocks",
  },
});

jsonApi.define("dividend", {
  date: "",
  amount: "",
  currency: "",
  created_at: "",
  updated_at: "",

  stock: {
    jsonApi: "hasOne",
    type: "stocks",
  },
});

jsonApi.define("direction", {
  name: "",
});

jsonApi.define("transaction", {
  date: "",
  amount: "0",
  price: "0",
  created_at: "",
  updated_at: "",
  deleted_at: "",

  direction: {
    jsonApi: "hasOne",
    type: "directions",
  },
});

jsonApi.define("news", {
  description: "",
  name: "",
  sentiment: 0,
  url: "",
  created_at: "",
  updated_at: "",
  date: "",

  newsSource: {
    jsonApi: "hasOne",
    type: "news-source",
  },

  newsCategories: {
    jsonApi: "hasMany",
    type: "news-category",
  },

  stocks: {
    jsonApi: "hasMany",
    type: "stocks",
  },

  securities: {
    jsonApi: "hasMany",
    type: "security",
  },
});

jsonApi.define("news-source", {
  name: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("news-category", {
  name: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("day-price", {
  date: "",
  price: 0,
  created_at: "",
  updated_at: "",
});

jsonApi.define("day-price-period", {
  date: "",
});

jsonApi.define("confirmation-code", {
  id: "",
  code: "",
});

jsonApi.define("password-confirmation-code", {
  email: "",
  code: "",
  password: "",
  confirmation: "",
});

jsonApi.define("view-history", {
  id: "",
  created_at: "",

  security: {
    jsonApi: "hasOne",
    type: "currency",
  },
});

jsonApi.define("country", {
  id: "",
  name: "",
  alpha_3: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("exchange", {
  id: "",
  name: "",
  code: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("portfolio-security", {
  id: "",
  amount: "",
  created_at: "",
  updated_at: "",
});

jsonApi.define("balance-sheet", {
  created_at: "",
  currency: "",
  current_assets_accounts_receivable: "",
  current_assets_cash: "",
  current_assets_cash_equivalents: "",
  current_assets_inventory: "",
  current_assets_other_current_assets: "",
  current_assets_other_receivables: "",
  current_assets_other_short_term_investments: "",
  current_assets_total_current_assets: "",
  current_liabilities_accounts_payable: "",
  current_liabilities_accrued_expenses: "",
  current_liabilities_deferred_revenue: "",
  current_liabilities_other_current_liabilities: "",
  current_liabilities_short_term_debt: "",
  current_liabilities_total_current_liabilities: "",
  fiscal_date: "",
  investments_and_advances: "",
  non_current_assets_accumulated_depreciation: "",
  non_current_assets_goodwill: "",
  non_current_assets_intangible_assets: "",
  non_current_assets_land_and_improvements: "",
  non_current_assets_leases: "",
  non_current_assets_machinery_furniture_equipment: "",
  non_current_assets_other_non_current_assets: "",
  non_current_assets_properties: "",
  non_current_assets_total_non_current_assets: "",
  non_current_liabilities_deferred_liabilities: "",
  non_current_liabilities_long_term_debt: "",
  non_current_liabilities_other_non_current_liabilities: "",
  non_current_liabilities_provision_for_risks_and_charges: "",
  non_current_liabilities_total_non_current_liabilities: "",
  shareholders_equity_common_stock: "",
  shareholders_equity_other_shareholders_equity: "",
  shareholders_equity_retained_earnings: "",
  shareholders_equity_total_shareholders_equity: "",
  total_assets: "",
  total_liabilities: "",
  updated_at: "",
});

jsonApi.define("income-statement", {
  id: "",
  fiscal_date: "",
  basic_shares_outstanding: "",
  cost_of_goods: "",
  diluted_shares_outstanding: "",
  ebitda: "",
  eps_basic: "",
  eps_diluted: "",
  gross_profit: "",
  income_tax: "",
  net_income: "",
  non_operating_interest_expense: "",
  non_operating_interest_income: "",
  operating_expense_research_and_development: "",
  operating_expense_selling_general_and_administrative: "",
  operating_income: "",
  other_income_expense: "",
  pretax_income: "",
  sales: "",
  created_at: "",
  updated_at: "",
});

let requestMiddleware = {
  name: "update-access-token-before-request",
  req: async (payload) => {
    if (
      payload.jsonApi.bearer !== null &&
      payload.req.model !== "refresh-token" &&
      !store.getters.isTokenValid
    ) {
      await store.dispatch("refresh").then(() => {
        payload.jsonApi.bearer = localStorage.getItem("accessToken");
        payload.req.headers.Authorization =
          "Bearer " + localStorage.getItem("accessToken");
      });
    }

    return payload;
  },
};

const errorMiddleware = {
  name: "jsonapi-errors",
  error: async function (err) {
    if (
      err.response &&
      err.response.status === 401 &&
      err.response.data.errors[0].code === "2"
    ) {
      await store.dispatch("refresh").then(() => {
        window.location.reload();
      });

      return err;
    }

    if (err.response && err.response.status === 404) {
      return err;
    }

    if (err.response && err.response.data) {
      err.response.data.mappedErrors = err.response.data.errors.reduce(
        (accumulator, errorObject) => {
          if (typeof errorObject.source === "undefined") {
            return false;
          }

          if (typeof errorObject.source.pointer === "undefined") {
            return err;
          }

          const errorFieldName = errorObject.source.pointer.split("/")[3];
          if ("meta" in errorObject) {
            for (const [key, value] of Object.entries(errorObject.meta)) {
              errorObject.detail = errorObject.detail.replace(":" + key, value);
            }
          }

          if (!(errorFieldName in accumulator)) {
            accumulator[errorFieldName] = errorObject.detail;
          }

          return {
            ...accumulator,
            [errorFieldName]: accumulator[errorFieldName],
          };
        },
        {}
      );
    }

    return err;
  },
};

jsonApi.insertMiddlewareBefore("axios-request", requestMiddleware);
jsonApi.replaceMiddleware("errors", errorMiddleware);

export default jsonApi;
