<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">{{ t("Holdings") }}</h5>
                </div>
              </div>
            </template>

            <div class="row">
              <div
                class="col-12 col-sm-6 col-xl-4"
                :key="sec.id"
                v-for="sec in secs"
              >
                <holding :security="sec" @deleted="deleteHolding"></holding>
              </div>
            </div>

            <template v-slot:footer v-if="totalSecsPages > 1">
              <base-pagination
                v-model="secsCurrentPage"
                :page-count="totalSecsPages"
                @update:modelValue="updatePortfolioSecs"
                class="justify-content-end"
              ></base-pagination>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Holding from "./Holding";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import { useI18n } from "vue-i18n";

export default {
  name: "Holdings",
  components: {
    Holding,
    RouteBreadCrumb,
  },
  watch: {
    $route(to) {
      if (to.name === "holdings" && to.params.id) {
        this.fetchData(to.params.id);
      }
    },
  },
  computed: {
    totalSecsPages() {
      if (this.secsPagesCursors[this.secsCurrentPage] === 0) {
        return this.secsCurrentPage;
      }

      return this.secsCurrentPage + 1;
    },
  },
  created() {
    this.fetchData(this.$route.params.id);
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  data() {
    return {
      secs: [],
      secsPagesCursors: {},
      secsCurrentPage: 1,
    };
  },
  methods: {
    fetchData(id) {
      this.$store
        .dispatch("getPortfolio", id)
        .then(({ data }) => {
          this.portfolio = data;
        })
        .catch((err) => {
          if (err.message && err.message.search("404") !== -1) {
            this.$router.push({
              name: "NotFound",
            });

            return;
          }

          this.processError(err);
        });

      this.updatePortfolioSecs(1);
    },
    updatePortfolioSecs(currentPage) {
      this.secsCurrentPage = currentPage;

      this.$store
        .dispatch("getPortfolioSecurities", {
          pid: this.$route.params.id,
          cursor: this.secsPagesCursors[this.secsCurrentPage - 1],
          size: 900,
          sort: "id",
        })
        .then(({ data, meta }) => {
          this.secs = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.secsPagesCursors[this.secsCurrentPage] =
              meta.pagination.cursor.next;
          } else {
            this.secsPagesCursors[this.secsCurrentPage] = 0;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    deleteHolding(pid, sid) {
      this.secs.forEach((v, i) => {
        if (v.id === sid) {
          this.secs.splice(i, 1);
        }
      });
    },
    processError(err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 400)
      ) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
