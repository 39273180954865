<template>
  {{ formatChangePrice(value) }}
</template>

<script>
import Big from "big.js";

export default {
  name: "change-price",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatChangePrice(val) {
      if (val === "") {
        return val;
      }

      return new Big(val).toFixed(2);
    },
  },
};
</script>
