<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <portfolio
          :portfolio="portfolio"
          v-if="portfolio.id !== ''"
        ></portfolio>

        <div class="col-xl-3 col-md-6">
          <card>
            <router-link
              :to="{
                name: 'Create portfolio',
                params: { lang: $route.params.lang },
              }"
              class="btn btn-outline-success"
            >
              {{ t("Add portfolio") }}
            </router-link>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="border-0 card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">{{ t("Transactions") }}</h3>
                </div>
                <div class="col text-right">
                  <router-link
                    class="btn btn-sm btn-primary"
                    :to="{
                      name: 'Add transaction',
                      params: $route.params,
                      lang: $route.params.lang,
                    }"
                    >{{ t("Add") }}
                  </router-link>
                </div>
              </div>
            </div>

            <el-config-provider :locale="plusLocale">
              <el-table
                class="table-responsive table-flush"
                header-row-class-name="thead-light"
                :data="transactions"
              >
                <el-table-column
                  :label="t('amount')"
                  min-width="150px"
                  prop="amount"
                >
                  <template v-slot="{ row }">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          row.amount
                        }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('price')"
                  min-width="150px"
                  prop="price"
                >
                  <template v-slot="{ row }">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="font-weight-600 name mb-0 text-sm"
                          >{{ row.price }} {{ security.currency.name }}</span
                        >
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('cost')"
                  min-width="150px"
                  prop="cost"
                >
                  <template v-slot="{ row }">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="font-weight-600 name mb-0 text-sm"
                          >{{ calcCost(row.amount, row.price) }}
                          {{ security.currency.name }}</span
                        >
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('direction')"
                  min-width="150px"
                  prop="cost"
                >
                  <template v-slot="{ row }">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span
                          class="font-weight-600 name mb-0 text-sm text-success"
                          v-if="row.direction.id === '1'"
                          >{{ t("buy") }}</span
                        >
                        <span
                          class="font-weight-600 name mb-0 text-sm text-danger"
                          v-if="row.direction.id === '2'"
                          >{{ t("sell") }}</span
                        >
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('date')"
                  min-width="150px"
                  prop="date"
                >
                  <template v-slot="{ row }">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          formatDate(row.date)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('actions')"
                  min-width="150px"
                  prop="actions"
                >
                  <template v-slot="{ row }">
                    <router-link
                      :to="{
                        name: 'Edit transaction',
                        params: {
                          id: $route.params.id,
                          sid: $route.params.sid,
                          tid: row.id,
                          lang: $route.params.lang,
                        },
                      }"
                    >
                      <i class="ni ni-settings"></i>
                    </router-link>
                    &nbsp;
                    <a href="#" @click.prevent="deleteTransaction(row.id)"
                      ><i class="ni ni-basket text-danger"></i
                    ></a>
                  </template>
                </el-table-column>
              </el-table>
            </el-config-provider>

            <div class="card-footer py-4 d-flex justify-content-end">
              <base-pagination
                v-model="currentPage"
                :page-count="totalPages"
                @update:modelValue="updateTransactions"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Portfolio from "@/views/Portfolios/Portfolio";
import { format } from "date-fns";
import Notification from "@/components/Notification";
import Big from "big.js";

import {
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTable,
  ElTableColumn,
  ElConfigProvider,
} from "element-plus";

import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import ru from "element-plus/lib/locale/lang/ru";
import en from "element-plus/lib/locale/lang/en";

export default {
  name: "transactions-list",
  components: {
    RouteBreadCrumb,
    Portfolio,
    ElConfigProvider,

    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      portfolio: {
        id: "",
        name: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },

      security: {
        id: "",
        bond: null,
        stock: null,
        quote: null,
        currency: {},
      },

      transactions: [],
      pagesCursors: {},
      currentPage: 1,
    };
  },
  watch: {
    $route(to) {
      if (to.params.id && to.params.sid) {
        this.fetchData(to.params.id, to.params.sid);
      }
    },
  },
  computed: {
    totalPages() {
      if (this.pagesCursors[this.currentPage] === 0) {
        return this.currentPage;
      }

      return this.currentPage + 1;
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const plusLocale = locale.value === "en" ? en : ru;

    return { t, locale, plusLocale };
  },
  created() {
    this.fetchData(this.$route.params.id, this.$route.params.sid);
  },
  methods: {
    async fetchData(id, sid) {
      let err = await this.updatePortfolio(id);

      if (typeof err !== "undefined") {
        return;
      }

      err = await this.$store
        .dispatch("getSecurity", sid)
        .then(({ data }) => {
          this.security = data;
        })
        .catch((err) => {
          if (err.message && err.message.search("404") !== -1) {
            this.$router.push({
              name: "NotFound",
            });

            return;
          }

          this.processError(err);

          return err;
        });

      if (typeof err !== "undefined") {
        return;
      }

      this.updateTransactions();
    },
    formatDate(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
    async updatePortfolio(pid) {
      return await this.$store
        .dispatch("getPortfolio", pid)
        .then(({ data }) => {
          this.portfolio = data;
        })
        .catch((err) => {
          if (err.message && err.message.search("404") !== -1) {
            this.$router.push({
              name: "NotFound",
            });

            return err;
          }

          this.processError(err);

          return err;
        });
    },
    updateTransactions() {
      this.$store
        .dispatch("getTransactions", {
          pid: this.$route.params.id,
          sid: this.$route.params.sid,
          cursor: this.pagesCursors[this.currentPage - 1],
        })
        .then(({ data, meta }) => {
          this.transactions = data;

          if (
            meta &&
            meta.pagination &&
            meta.pagination.cursor &&
            meta.pagination.cursor.next
          ) {
            this.pagesCursors[this.currentPage] = meta.pagination.cursor.next;
          } else {
            this.pagesCursors[this.currentPage] = 0;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$router.push({ name: "NotFound" });

            return;
          }

          this.processError(err);
        });
    },
    deleteTransaction(tid) {
      this.$store
        .dispatch("deleteTransaction", {
          tid: tid,
          pid: this.$route.params.id,
          sid: this.$route.params.sid,
        })
        .then(() => {
          let that = this;

          this.transactions.forEach(function (tx, i) {
            if (tx.id === tid) {
              that.transactions.splice(i, 1);
            }
          });

          this.updatePortfolio(this.$route.params.id);
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    calcCost(amount, price) {
      return new Big(amount).times(new Big(price)).toFixed();
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 400)
      ) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
};
</script>

<style scoped></style>
