<template>
  <div class="bg-default rounded">
    <card class="bg-default" header-classes="bg-default" body-classes="pt-0">
      <template v-slot:header class="bg-default">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="h3 text-light mb-0">{{ t("Market performance") }}</h3>
          </div>
          <div class="col text-right">
            <el-select
              :placeholder="t('Market')"
              v-model="country_id"
              filterable
              @change="changeCountry"
            >
              <el-option
                v-for="option in countries"
                class="select-danger"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <h3 class="text-white">{{ t("Top gainers") }}</h3>
          <div class="table-responsive">
            <el-config-provider :locale="plusLocale">
              <el-table
                class="table-responsive table-dark"
                :data="gainers"
                header-row-class-name="thead-dark"
              >
                <el-table-column
                  :label="t('name')"
                  min-width="115px"
                  prop="name"
                >
                  <template v-slot="{ row }">
                    <router-link
                      :to="{
                        name: 'Security',
                        params: { id: row.id, lang: $route.params.lang },
                      }"
                      >{{ row.stock.name }}
                    </router-link>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('ticker')"
                  min-width="115px"
                  prop="ticker"
                >
                  <template v-slot="{ row }">
                    <div class="font-weight-600">
                      {{ row.stock.ticker }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column :label="t('1d')" min-width="110px" prop="1d">
                  <template v-slot="{ row }">
                    <span
                      class="text-success mr-2"
                      v-if="
                        row.quote && row.quote.percent_change.search('\-') !== 0
                      "
                      ><i class="fa fa-arrow-up"></i>
                      {{ formatChange(row.quote.percent_change) }}%</span
                    >

                    <span
                      class="text-warning mr-2"
                      v-if="
                        row.quote && row.quote.percent_change.search('\-') === 0
                      "
                      ><i class="fa fa-arrow-down"></i>
                      {{ formatChange(row.quote.percent_change) }}%</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-config-provider>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <h3 class="text-white">{{ t("Top losers") }}</h3>
          <div class="table-responsive">
            <el-config-provider :locale="plusLocale">
              <el-table
                class="table-responsive table-dark"
                :data="losers"
                header-row-class-name="thead-dark"
              >
                <el-table-column
                  :label="t('name')"
                  min-width="115px"
                  prop="name"
                >
                  <template v-slot="{ row }">
                    <div class="font-weight-600">
                      <router-link
                        :to="{
                          name: 'Security',
                          params: { id: row.id, lang: $route.params.lang },
                        }"
                        >{{ row.stock.name }}
                      </router-link>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="t('ticker')"
                  min-width="115px"
                  prop="ticker"
                >
                  <template v-slot="{ row }">
                    <div class="font-weight-600">
                      {{ row.stock.ticker }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column :label="t('1d')" min-width="110px" prop="1d">
                  <template v-slot="{ row }">
                    <span
                      class="text-success mr-2"
                      v-if="
                        row.quote && row.quote.percent_change.search('\-') !== 0
                      "
                      ><i class="fa fa-arrow-up"></i>
                      {{ formatChange(row.quote.percent_change) }}%</span
                    >

                    <span
                      class="text-warning mr-2"
                      v-if="
                        row.quote && row.quote.percent_change.search('\-') === 0
                      "
                      ><i class="fa fa-arrow-down"></i>
                      {{ formatChange(row.quote.percent_change) }}%</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-config-provider>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElConfigProvider,
} from "element-plus";
import Card from "@/components/Cards/Card";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import Big from "big.js";
import { useI18n } from "vue-i18n";
import ru from "element-plus/lib/locale/lang/ru";
import en from "element-plus/lib/locale/lang/en";

export default {
  name: "MarketPerformance",
  components: {
    Card,
    ElConfigProvider,

    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "MarketPerformance") {
          document.title =
            this.t("Market performance") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  data: function () {
    return {
      countries: [],
      gainers: [],
      losers: [],
      country_id: "236",
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const plusLocale = locale.value === "en" ? en : ru;

    return { t, locale, plusLocale };
  },
  created() {
    if (!this.$store.getters.parsedToken) {
      return;
    }

    this.getCountries();
    this.getGainers(this.country_id);
    this.getLosers(this.country_id);
  },
  methods: {
    getGainers(country_id) {
      this.$store
        .dispatch("getSecurities", {
          cursor: 0,
          pageSize: 5,
          sort: "-quote.percent_change",
          country_id: country_id,
        })
        .then(({ data }) => {
          this.gainers = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getLosers(country_id) {
      this.$store
        .dispatch("getSecurities", {
          cursor: 0,
          pageSize: 5,
          sort: "quote.percent_change",
          country_id: country_id,
        })
        .then(({ data }) => {
          this.losers = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    getCountries() {
      this.$store
        .dispatch("getCountriesWithMM")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    changeCountry(id) {
      this.getGainers(id);
      this.getLosers(id);
    },
    formatChange(val) {
      if (val === "") {
        return val;
      }

      return new Big(val).toFixed(2);
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
};
</script>

<style scoped></style>
