<template>
  <modal :show="isShowAddToPortfolioModal">
    <base-input :label="t('Select portfolio')" name="portfolio">
      <select
        class="form-control"
        v-model="pid"
        required
        v-show="this.portfolios.length > 0"
      >
        <option
          v-for="portfolio in portfolios"
          :value="portfolio.id"
          :key="portfolio.id"
        >
          {{ portfolio.name }}
        </option>
      </select>
    </base-input>
    <router-link
      :to="{
        name: 'Create portfolio',
        params: { lang: $route.params.lang },
      }"
      class="text-light"
      ><small>{{ t("Create portfolio") }}</small></router-link
    >

    <template v-slot:footer>
      <base-button
        type="success"
        @click="add"
        :loading="processing"
        v-show="this.portfolios.length > 0"
        >{{ t("Add") }}
      </base-button>

      <base-button
        type="primary"
        @click="addAndGo"
        :loading="processing"
        v-show="this.portfolios.length > 0"
        >{{ t("Add and go to transactions") }}
      </base-button>

      <base-button
        type="link"
        class="ml-auto"
        @click="isShowAddToPortfolioModal = false"
        >{{ t("Close") }}
      </base-button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

export default {
  name: "AddSecurity",
  components: {
    Modal,
  },
  data: function () {
    return {
      sid: "",
      pid: "",
      isShowAddToPortfolioModal: false,
      portfolios: [],
      processing: false,
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    openAddToPortfolioModal(sid) {
      this.isShowAddToPortfolioModal = true;
      this.sid = sid;

      this.$store
        .dispatch("getPortfolios")
        .then(({ data }) => {
          this.portfolios = data;

          if (this.portfolios.length > 0) {
            this.pid = this.portfolios[0].id;
          }
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    async add() {
      try {
        this.processing = true;

        if (this.pid === "") {
          this.processing = false;

          return;
        }

        await this.$store.dispatch("addToPortfolio", {
          pid: this.pid,
          sid: this.sid,
        });

        this.runToast(
          "top-right",
          "success",
          this.t("Security added"),
          "ni ni-check-bold"
        );
        this.isShowAddToPortfolioModal = false;
        this.processing = false;
      } catch (err) {
        this.processError(err);
        this.processing = false;
      }
    },
    async addAndGo() {
      try {
        this.processing = true;

        if (this.pid === "") {
          this.processing = false;

          return;
        }

        await this.$store.dispatch("addToPortfolio", {
          pid: this.pid,
          sid: this.sid,
        });

        this.runToast(
          "top-right",
          "success",
          this.t("Security added"),
          "ni ni-check-bold"
        );
        this.isShowAddToPortfolioModal = false;
        this.processing = false;

        await this.$router.push({
          name: "Transactions",
          params: {
            id: this.pid,
            sid: this.sid,
            lang: this.$route.params.lang,
          },
        });
      } catch (err) {
        this.processError(err);
        this.processing = false;
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 400)
      ) {
        this.runToast(
          "top-right",
          "danger",
          this.t(err.response.data.errors[0]["detail"]),
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
};
</script>

<style scoped></style>
