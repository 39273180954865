<template>
  <div class="col-xl-3 col-md-6">
    <card>
      <div class="row">
        <div class="col">
          <slot>
            <h5
              class="portfolio-title card-title text-uppercase text-muted mb-0"
              @click="onClick"
            >
              {{ portfolio.name }}
            </h5>
            <span class="h2 font-weight-bold mb-0"
              ><price :value="portfolio.price.toString()"></price>
              {{ portfolio.currency.name }}</span
            >
          </slot>
        </div>
      </div>
      <div class="row">
        <div class="col-8 lead text-sm mt-3 mb-0">
          <span class="text-success mr-2" v-if="portfolio.month_change >= 0"
            ><i class="fa fa-arrow-up"></i>&nbsp;<change-price
              :value="portfolio.month_change.toString()"
            ></change-price
            >%</span
          >
          <span class="text-danger mr-2" v-if="portfolio.month_change < 0"
            ><i class="fa fa-arrow-down"></i>&nbsp;<change-price
              :value="portfolio.month_change.toString()"
            ></change-price
            >%</span
          >
          <span class="text-nowrap">{{ t("1M") }}</span>
        </div>
        <div class="col-4 mt-3 mb-0 text-right">
          <base-dropdown
            title-classes="btn btn-sm mt-0 portfolio-menu"
            icon="fas fa-ellipsis-h text-primary"
            :has-toggle="hasToggle"
          >
            <span
              class="dropdown-item"
              @click="openUpdateModal(portfolio.id, portfolio.name)"
              ><i class="fa fa-edit mr-1"></i> {{ t("Rename") }}</span
            >
            <span
              class="dropdown-item"
              @click.prevent="deletePortfolio(portfolio.id)"
              ><i class="fa fa-trash text-danger mr-2"></i>
              {{ t("Delete") }}</span
            >
          </base-dropdown>
          <!--          <span class="btn btn-sm mt-0"-->
          <!--            ><i class="fas fa-ellipsis-h text-primary"></i-->
          <!--          ></span>-->
          <!--          <span class="btn btn-sm"><i class="fas fa-trash mt-2"></i></span>-->
        </div>
      </div>
    </card>
  </div>

  <edit-modal
    v-if="editModalOpened"
    :name="name"
    :pid="pid"
    @closed="editModalOpened = false"
    @updated="update"
  ></edit-modal>
</template>

<script>
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import EditModal from "@/views/Portfolios/EditModal";
import Price from "../Widgets/Price";
import ChangePrice from "../Widgets/ChangePrice";
import { useI18n } from "vue-i18n";

export default {
  name: "portfolio",
  emits: ["deleted", "updated"],
  components: {
    EditModal,
    Price,
    ChangePrice,
  },
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      hasToggle: false,
      editModalOpened: false,

      pid: "",
      name: "",
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    onClick() {
      this.$router.push({
        name: "Portfolio",
        params: { id: this.portfolio.id, lang: this.$route.params.lang },
      });
    },
    async deletePortfolio(pid) {
      try {
        await this.$store.dispatch("deletePortfolio", { pid: pid });

        this.runToast(
          "top-right",
          "success",
          this.t("Portfolio deleted"),
          "ni ni-bold-down"
        );

        this.$emit("deleted", pid);
      } catch (err) {
        this.processError(err);
      }
    },
    openUpdateModal(pid, name) {
      this.editModalOpened = true;
      this.pid = pid;
      this.name = name;
    },
    update(payload) {
      this.$emit("updated", payload);
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped>
.portfolio-title {
  cursor: pointer;
}
</style>
