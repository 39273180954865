import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getTransactions: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .one("security", payload.sid)
          .all("transaction")
          .get({
            page: {
              size: 100,
              cursor: payload.cursor,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    createTransaction: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .one("security", payload.sid)
          .all("transaction")
          .post(payload.data)
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getTransaction: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .one("security", payload.sid)
          .one("transaction", payload.tid)
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    deleteTransaction: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .one("security", payload.sid)
          .one("transaction", payload.tid)
          .destroy()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    updateTransaction: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("portfolio", payload.pid)
          .one("security", payload.sid)
          .one("transaction", payload.tid)
          .patch(payload.data)
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
  },
};
