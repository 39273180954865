import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getPortfoliosDayPrices: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("user", payload.uid)
          .all("day-price")
          .get({
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getPortfoliosDayPricesPeriods: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("user", payload.uid)
          .all("day-price-period")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    requestEmailConfirmationCode: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("confirmation-code")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    sendEmailConfirmationCode: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("confirmation-code", payload.id)
          .post({ code: payload.code })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getViewHistory: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        let old = jsonApi.pluralize;
        jsonApi.pluralize = function (s) {
          return s;
        };
        jsonApi
          .one("users", payload.uid)
          .all("view-history")
          .get({
            page: {
              size: payload.pageSize,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));

        jsonApi.pluralize = old;
      }),
  },
};
