<script>
export default {
  name: "CatchAll",
  data() {
    return {};
  },
  created() {
    window.location.href = "/404";
  },
};
</script>

<style scoped></style>
