export default {
  "Dashboard root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Screener root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screener"])},
  "Portfolios root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolios"])},
  "Portfolio root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
  "Portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
  "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "Transactions root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "Add transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add transaction"])},
  "Edit transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit transaction"])},
  "Time Not Supplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time not supplied"])}
}