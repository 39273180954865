<template>
  <portfolio
    v-for="portfolio in portfolios"
    :key="portfolio.id"
    :portfolio="portfolio"
    @deleted="deletePortfolio"
    @updated="updatePortfolio"
  ></portfolio>

  <div class="col-xl-3 col-md-6">
    <card>
      <router-link
        :to="{
          name: 'Create portfolio',
          params: { lang: $route.params.lang },
        }"
        class="btn btn-outline-success"
      >
        {{ t("Add portfolio") }}
      </router-link>
    </card>
  </div>
</template>

<script>
import Portfolio from "@/views/Portfolios/Portfolio";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

export default {
  name: "portfolios",
  components: {
    Portfolio,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "Portfolios") {
          document.title =
            this.t("Portfolios") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  data() {
    return {
      portfolios: [],
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  created() {
    if (!this.$store.getters.parsedToken) {
      return;
    }

    this.getPortfolios();
  },
  methods: {
    getPortfolios() {
      this.$store
        .dispatch("getPortfolios")
        .then(({ data }) => {
          this.portfolios = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    deletePortfolio(pid) {
      this.portfolios.forEach((v, i) => {
        if (v.id === pid) {
          this.portfolios.splice(i, 1);
        }
      });
    },
    updatePortfolio(payload) {
      this.portfolios.forEach((v, i) => {
        if (v.id === payload.pid) {
          this.portfolios[i].name = payload.name;
        }
      });
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
  },
};
</script>

<style scoped></style>
