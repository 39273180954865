<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ t($route.name) }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <portfolio
          :portfolio="portfolio"
          v-if="portfolio.id !== ''"
        ></portfolio>

        <div class="col-xl-3 col-md-6">
          <card>
            <router-link
              :to="{
                name: 'Create portfolio',
                params: { lang: $route.params.lang },
              }"
              class="btn btn-outline-success"
            >
              {{ t("Add portfolio") }}
            </router-link>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <template v-slot:header>
                <h3 class="mb-0">{{ t("Edit transaction") }}</h3>
              </template>
              <!-- Card body -->

              <transaction-form
                v-if="transaction.id !== ''"
                ref="form"
                @valid="onSubmit"
                :date="formatDate(new Date(transaction.date), 'yyyy-MM-dd')"
                :amount="transaction.amount"
                :price="transaction.price"
                :direction="transaction.direction.id.toString()"
              ></transaction-form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import Portfolio from "@/views/Portfolios/Portfolio";
import TransactionForm from "@/views/Portfolios/Portfolio/Transactions/Form";
import { format as formatDate } from "date-fns";
import { useI18n } from "vue-i18n";

export default {
  name: "transactions-add",
  components: {
    RouteBreadCrumb,
    Portfolio,
    TransactionForm,
  },
  watch: {
    $route(to) {
      if (to.params.id && to.params.sid && to.params.tid) {
        this.fetchData(
          this.$route.params.id,
          this.$route.params.sid,
          this.$route.params.tid
        );
      }
    },
  },
  created() {
    this.fetchData(
      this.$route.params.id,
      this.$route.params.sid,
      this.$route.params.tid
    );
  },
  data() {
    return {
      portfolio: {
        id: "",
        name: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      transaction: {
        id: "",
      },
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    fetchData(pid, sid, tid) {
      this.$store
        .dispatch("getPortfolio", pid)
        .then(({ data }) => {
          this.portfolio = data;
        })
        .catch((err) => {
          this.processError(err);
        });

      this.$store
        .dispatch("getTransaction", {
          pid: pid,
          sid: sid,
          tid: tid,
        })
        .then(({ data }) => {
          this.transaction = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    async onSubmit(values) {
      try {
        await this.$store.dispatch("updateTransaction", {
          pid: this.$route.params.id,
          sid: this.$route.params.sid,
          tid: this.$route.params.tid,
          data: {
            date: values.date,
            amount: values.amount,
            price: values.price,
            direction: {
              id: values.direction,
              type: "directions",
            },
          },
        });

        this.runToast(
          "top-right",
          "success",
          "Transaction info updated",
          "ni ni-bold-down"
        );

        this.$router.push({
          name: "Transactions",
          params: { id: this.$route.params.id, sid: this.$route.params.sid },
        });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          this.$refs.form.setErrors(err.response.data.mappedErrors);
        } else {
          this.processError(err);
        }
      }
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
  },
};
</script>

<style scoped></style>
