"use strict";

import store from "../store";
import i18n from "../i18n";

export default function guest({ next }) {
  if (store.getters.isAuthenticated && store.getters.isTokenValid) {
    return next({
      name: "Dashboard root",
      params: { lang: i18n.global.locale.value },
    });
  }

  return next();
}
