export default {
  "Progressive securities scoring service and portfolio tracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогрессивный сервис скоринга ценных бумаг и портфельный трекер"])},
  "Stockwayup - progressive securities scoring service and portfolio tracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockwayup - прогрессивный сервис скоринга ценных бумаг и портфельный трекер"])},
  "Plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "Start free plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать бесплатно"])},
  "Portfolios summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость портфелей"])},
  "Explore investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открой новые"])},
  "opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["инвестиционные возможности"])},
  "Create and track your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создай и отслеживай прогресс своего"])},
  "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["портфеля"])},
  "Be aware of important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь в курсе важных"])},
  "events and news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["событий и новостей"])},
  "Save your time searching for information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономь время на поиске информации"])},
  "Make informed decisions based on full set of analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимай взвешенные решения на основе полного набора аналитических данных"])},
  "Portfolios and analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфели и аналитика"])},
  "1 month till today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последний месяц"])},
  "Full control over the portfolio.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный контроль над портфелем."])},
  "Monitor the profitability of your securities, explore charts, estimate profits and commissions. Now all money is under your control.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отслеживай доходность своих ценных бумаг, изучай графики, оценивай прибыль и комиссионные. Теперь все деньги под твоим контролем."])},
  "Make your portfolio public.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделай свой портфель публичным."])},
  "Just choose what you want to show to others, share your successes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто выбери, что хочешь показать другим, поделись своими успехами."])},
  "News and updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости и события"])},
  "Stockwayup news provides articles from reputable news sources such as: CNBC, Zacks, Bloomberg, The Motley Fool, Fox Business, The Street, and others.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В новостях Stockwayup представлены статьи из авторитетных новостных источников, таких как: CNBC, Zacks, Bloomberg, The Motley Fool, Fox Business, The Street и других."])},
  "Each news item has a sentiment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У каждой новости есть оценка эмоциональной окраски."])},
  "This can be positive, negative, or neutral. This is particularly useful for easy sentiment analysis.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Она может быть положительной, отрицательной или нейтральной. Это особенно полезно для быстрого анализа настроений на рынке."])},
  "Each news item has topics.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У каждой новости есть теги."])},
  "This is particularly useful for easy search by keywords, category, company ticker, industry.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это особенно полезно для удобного поиска по ключевым словам, категориям, тикерам, отраслям."])},
  "The most relevant and exhaustive news is always at hand.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые актуальные и исчерпывающие новости всегда под рукой."])},
  "Calendar and events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь и события"])},
  "All the most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все самые"])},
  "important events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["важные события"])},
  "in a comfortable format in one place. Calendar customization for your interests.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в удобном формате собраны в одном месте. Календарь подстраивается под твои интересы."])},
  "Individual calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальный календарь"])},
  "for each portfolio and a common calendar to keep track of all events at the same time.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для каждого портфеля и общий календарь, чтобы отслеживать все события одновременно."])},
  "Comfortable tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобное отслеживание"])},
  "of dividends, coupons, reports.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дивидендов, купонов, отчетов."])},
  "Securities and analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ценные бумаги и аналитика"])},
  "Current stock price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая цена акций"])},
  "with real-time updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с обновлением в реальном времени"])},
  "History of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
  "price changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изменений цены"])},
  "over time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за все время"])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
  "which caused price changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вызвавшие изменение цены"])},
  "Company valuation:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценка компаний:"])},
  "undervalued or  overvalued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["недооцененные или переоцененные"])},
  "Real": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реальная "])},
  "share price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цена акций"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
  "fair value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["справедливая стоимость"])},
  "Future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущий"])},
  "company growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рост компании"])},
  "earnings and revenue growth forecasts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прибыль и прогнозы роста выручки"])},
  "Company profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о прибыли"])},
  "and revenue Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и выручке компании"])},
  "Financial position analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ финансового положения"])},
  "balance sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бухгалтерский баланс"])},
  "Debt history and analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История и анализ структуры задолженностей"])},
  "Dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивиденды"])},
  "and growth of payments analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и анализ роста выплат"])},
  "Team information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о команде"])},
  "board members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["совете директоров"])},
  "Accounting and control of your investments.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учет и контроль."])},
  "Just add stocks and bonds transactions, your income is calculated automatically. Keep track of your portfolio and explore detailed analytics.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто добавь сделки с акциями и облигациями, доход будет рассчитан автоматически. Отслеживай состояние портфеля и получай подробную аналитику."])},
  "Quick start.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрый старт."])},
  "Import all your transactions and add new deals in a few clicks.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортируй все транзакции и сделки в несколько кликов."])},
  "Stock Screener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринер акций"])},
  "Stock screener can help you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринер акций поможет"])},
  "to find securities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["найти ценные бумаги"])},
  "by different parameters. Filters and quick addition of stocks to the portfolio.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по разным параметрам. Фильтры и быстрое добавление бумаг в портфель."])},
  "Plans and Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы и цены"])},
  "Unlimited portfolios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безлимитное количество портфелей"])},
  "Unlimited watch list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безлимитный watch list"])},
  "Stocks screener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринер ценных бумаг"])},
  "Dividends and reports calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь дивидендов и отчетов"])},
  "$0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 ₽"])},
  "for early users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для ранних пользователей"])},
  "$14.99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999 ₽"])},
  "per month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
  "Even more features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще больше возможностей"])},
  "Coming soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро"])},
  "Contact sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с отделом продаж"])},
  "Sign up for free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатная регистрация"])},
  "Ready to get started?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовы начать?"])},
  "create a free account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["создайте бесплатный аккаунт"])},
  "Choose the best plan for your needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подходящий план для ваших нужд"])},
  "Welcome!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
  "Sign in with credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите, используя учетные данные"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])},
  "Create new account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый аккаунт"])},
  "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "Email/password pair not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пара email/пароль не найдены"])},
  "Create an account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание аккаунта"])},
  "Sign up with credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Repeat password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
  "I agree with the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимаю"])},
  "Terms and conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
  "Create account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "Forgot password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
  "Reset the password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "Reset password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля"])},
  "Enter the email you used during registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите email, который использовался при регистрации"])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "Portfolios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфели"])},
  "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
  "Screener": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринер"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
  "1M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1М"])},
  "Add portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать портфель"])},
  "Events calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь событий"])},
  "Market performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взлеты и падения"])},
  "Top gainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидеры роста"])},
  "Top losers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лидеры падения"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["название"])},
  "ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тикер"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цена"])},
  "1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1д"])},
  "Rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "Saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранено"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поиск"])},
  "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена"])},
  "Invalid request param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный параметр запроса"])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "Create portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать портфель"])},
  "add securities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добавьте ценные бумаги"])},
  "Add securities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте ценные бумаги"])},
  "to portfolio to see news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в портфель, чтобы видеть новости"])},
  "No news found for this security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости не найдены"])},
  "You have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас"])},
  "notifications.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["уведомлений"])},
  "Portfolio summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость портфеля"])},
  "Holdings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы"])},
  "See all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все"])},
  "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шт"])},
  "Transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["стоимость"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["количество"])},
  "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["направление"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дата"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действия"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["покупка"])},
  "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["продажа"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "Recently viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно просмотренные"])},
  "Portfolio name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название портфеля"])},
  "Market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
  "Period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
  "Edit profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение профиля"])},
  "User information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о пользователе"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
  "Paste code here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте код сюда"])},
  "Old password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
  "New password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "Confirm password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
  "Change password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
  "Email not confirmed. Please confirm your email to keep your account safe.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не подтвержден. Пожалуйста подтвердите email, чтобы сохранить аккаунт в безопасности."])},
  "Email not confirmed. Please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не подтвержден. Пожалуйста"])},
  "confirm your email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подтвердите email"])},
  "to keep your account safe.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чтобы сохранить аккаунт в безопасности"])},
  "Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "Resend confirmation code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить код повторно"])},
  "Send confirmation code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить код подтверждения"])},
  "Confirm email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить email"])},
  "Email confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email подтвержден"])},
  "New confirmation code send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый код подтверждения отправлен"])},
  "Invalid or expired code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный или истекший код"])},
  "Code expired. Try resend code.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код истек. Отправьте новый код"])},
  "Enter the confirmation code sent to your email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код подтверждения, отправленный на ваш email"])},
  "The confirmation code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код подтверждения"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "Cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
  "Invalid value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное значение"])},
  "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сектор"])},
  "Industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индустрия"])},
  "Exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
  "Select portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор портфеля"])},
  "Add and go to transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить и перейти к транзакциям"])},
  "Add to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в "])},
  "Calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "Dashboard root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "Screener root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринер"])},
  "Portfolios root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфели"])},
  "Portfolio root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
  "Portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель"])},
  "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ценная бумага"])},
  "Transactions root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
  "Add transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление транзакции"])},
  "Edit transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование транзакции"])},
  "Income statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет о прибылях и убытках"])},
  "Balance position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "Balance sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бухгалтерский баланс"])},
  "Debt to equity history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношение долга к собственному капиталу"])},
  "Quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По кварталам"])},
  "Annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По годам"])},
  "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
  "earning release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отчет о доходах"])},
  "dividend payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выплата дивидендов"])},
  "Time Not Supplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["время не указано"])},
  "EPS estimate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPS ожидание"])},
  "EPS actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPS фактический"])},
  "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["разница"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "Welcome back!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С возвращением!"])},
  "!Password changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль изменен!"])},
  "Network error. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка. Попробуйте еще раз."])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка"])},
  "Sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа"])},
  "My profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой профиль"])},
  "The Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "The Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "Repeat the password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторение пароля"])},
  "${path} is invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " содержит неправильное значение"])},
  "${path} is a required field": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " обязательное поле"])},
  "${path} must be at least ${min} characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен содержать минимум $", _interpolate(_named("min")), " символов"])},
  "${path} must be less than ot equal ${max} characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен содержать максимум $", _interpolate(_named("max")), " символов"])},
  "${path} must be a valid email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен содержать валидный email"])},
  "You need to agree with our terms and conditions.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо принять условия использования"])},
  "Email is busy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email занят"])},
  "Password and Repeat password don't match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль и повтор пароля не совпадают"])},
  "${path} must be greater than or equal to ${min}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен быть больше или равен $", _interpolate(_named("min"))])},
  "${path} must be less than or equal to ${max}": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен быть меньше или равен $", _interpolate(_named("max"))])},
  "${path} must be an integer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("path")), " должен быть целым числом"])},
  "Date field must be at earlier than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата должна быть раньше или равна"])},
  "Date field must be at later than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата должна быть позже или равна"])},
  "Price must be greater than or equal to 0.000000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена должна быть выше или равна 0.000000001"])},
  "Welcome aboard!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать на борт!"])},
  "A confirmation code has been sent to your email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код для подтверждения отправлен на email"])},
  "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "Security added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ценная бумага добавлена"])},
  "Security already added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ценная бумага уже добавлена"])},
  "Portfolio created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель создан"])},
  "Portfolio deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портфель удален"])},
  "Price history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История цен"])}
}