import jsonApi from "@/jsonApi";

const accessToken = "accessToken";
const refreshToken = "refreshToken";
const resetPasswordCodeID = "resetPasswordCodeID";

export default {
  state: {
    refreshInProgress: false,
    accessToken: localStorage.getItem(accessToken),
    refreshToken: localStorage.getItem(refreshToken),
    resetPasswordCodeID: localStorage.getItem(resetPasswordCodeID),
  },

  getters: {
    isAuthenticated(state) {
      return (
        state.accessToken !== null &&
        state.refreshToken !== null &&
        state.accessToken !== "" &&
        state.refreshToken !== ""
      );
    },

    email(state, getters) {
      const jwt = getters.parsedToken;

      if (jwt === null) {
        return null;
      }

      return jwt.email;
    },

    isEmailConfirmed(state, getters) {
      const jwt = getters.parsedToken;

      if (jwt === null) {
        return null;
      }

      return jwt.email_confirmed_at !== null;
    },

    isTokenValid(state, getters) {
      const jwt = getters.parsedToken;

      if (jwt === null) {
        return false;
      }

      return Math.round(new Date().getTime() / 1000) <= jwt.exp;
    },

    parsedToken(state) {
      const token = state.accessToken;
      if (token === null || token === "") {
        return null;
      }

      return JSON.parse(atob(token.split(".")[1]));
    },

    resetPasswordCodeID(state) {
      return state.resetPasswordCodeID;
    },
  },

  mutations: {
    setRefreshInProgress(state) {
      state.refreshInProgress = true;
    },
    unsetRefreshInProgress(state) {
      state.refreshInProgress = false;
    },
    setAccessToken(state, val) {
      state.accessToken = val;

      if (val === "") {
        localStorage.removeItem(accessToken);
      } else {
        localStorage.setItem(accessToken, val);
      }
    },
    setRefreshToken(state, val) {
      state.refreshToken = val;

      if (val === "") {
        localStorage.removeItem(refreshToken);
      } else {
        localStorage.setItem(refreshToken, val);
      }
    },
    setResetPasswordCodeID(state, val) {
      state.resetPasswordCodeID = val;

      if (val === "") {
        localStorage.removeItem(resetPasswordCodeID);
      } else {
        localStorage.setItem(resetPasswordCodeID, val);
      }
    },
  },

  actions: {
    login: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi
          .create("session", payload)
          .then(({ data }) => {
            context.commit("setAccessToken", data["access-token"]);
            context.commit("setRefreshToken", data["refresh-token"]);

            resolve();
          })
          .catch((errors) => reject(errors));
      }),

    register: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi
          .create("user", payload)
          .then(({ data }) => {
            context.commit("setAccessToken", data["access-token"]);
            context.commit("setRefreshToken", data["refresh-token"]);

            resolve();
          })
          .catch((errors) => reject(errors));
      }),

    refresh: (context) =>
      new Promise((resolve, reject) => {
        if (context.state.refreshInProgress) {
          reject("skip refresh, refresh already in progress");

          return;
        }

        context.commit("setRefreshInProgress");

        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .create("refresh-token", {
            "refresh-token": localStorage.getItem(refreshToken),
          })
          .then(({ data }) => {
            context.commit("setAccessToken", data["access-token"]);
            context.commit("setRefreshToken", data["refresh-token"]);
            context.commit("unsetRefreshInProgress");

            resolve();
          })
          .catch((errors) => {
            context.commit("setAccessToken", "");
            context.commit("setRefreshToken", "");

            jsonApi.bearer = null;

            reject(errors);
          });
      }),

    logout: (context) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .destroy("refresh-token", localStorage.getItem(refreshToken))
          .then(() => {
            context.commit("setAccessToken", "");
            context.commit("setRefreshToken", "");

            jsonApi.bearer = null;

            resolve();
          })
          .catch((errors) => {
            context.commit("setAccessToken", "");
            context.commit("setRefreshToken", "");

            jsonApi.bearer = null;

            reject(errors);
          });
      }),

    resetPassword: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi
          .all("password-confirmation-code")
          .post({ email: payload.email })
          .then(({ data }) => {
            context.commit("setResetPasswordCodeID", data["id"]);

            resolve(data);
          })
          .catch((errors) => {
            context.commit("setResetPasswordCodeID", "");

            reject(errors);
          });
      }),

    confirmReset: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi
          .one("password-confirmation-code", payload.id)
          .post({
            code: payload.code,
            password: payload.password,
            confirmation: payload.confirmation,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((errors) => {
            console.error(errors);
            reject(errors);
          });
      }),
  },
};
