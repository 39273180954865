<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link
          :to="{
            name: 'Dashboard root',
            params: { lang: $route.params.lang },
          }"
        >
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>

    <BreadCrumbItem
      v-for="(route, index) in routes"
      :key="route.name"
      :active="index === routes.length - 1"
      style="display: inline-block"
    >
      <router-link
        :to="{ name: route.name, params: $route.params }"
        v-if="index < routes.length - 1"
      >
        {{ t(route.name) }}
      </router-link>
      <span v-else>
        {{ t(route.name) }}
      </span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb";
import BreadCrumbItem from "./BreadcrumbItem";
import { useI18n } from "vue-i18n";

export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  methods: {
    buildRoutesList(to) {
      let routes = [];

      to.matched.slice().forEach((value, index) => {
        if (
          index - 1 in routes &&
          typeof routes[index - 1].name !== "undefined" &&
          this.t(routes[index - 1].name) === this.t(value.name)
        ) {
          routes[index - 1] = value;
        } else {
          routes[index] = value;
        }
      });

      this.routes = routes;
    },
  },
  watch: {
    $route(to) {
      this.buildRoutesList(to);
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  computed: {
    items() {
      if (this.newsPagesCursors[this.newsCurrentPage] === 0) {
        return this.newsCurrentPage;
      }

      return this.newsCurrentPage + 1;
    },
  },
  data() {
    return {
      routes: [],
    };
  },
  created() {
    this.buildRoutesList(this.$route);
  },
};
</script>

<style scoped></style>
