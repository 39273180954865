<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://stockwayup.com"
            class="font-weight-bold ml-1"
            target="_blank"
            >Stockwayup</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a
              class="nav-link nav-link-icon"
              href="https://www.facebook.com/stockwayup"
              target="_blank"
              data-toggle="tooltip"
              data-original-title="Like us on Facebook"
            >
              <i class="fab fa-facebook-square"></i>
              <span class="nav-link-inner--text d-lg-none">Facebook</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nav-link-icon"
              href="https://www.instagram.com/stockwayup"
              target="_blank"
              data-toggle="tooltip"
              data-original-title="Follow us on Instagram"
            >
              <i class="fab fa-instagram"></i>
              <span class="nav-link-inner--text d-lg-none">Instagram</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nav-link-icon"
              href="https://twitter.com/stockwayup"
              target="_blank"
              data-toggle="tooltip"
              data-original-title="Follow us on Twitter"
            >
              <i class="fab fa-twitter-square"></i>
              <span class="nav-link-inner--text d-lg-none">Twitter</span>
            </a>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              href="https://stockwayup.com/faq"-->
          <!--              class="nav-link"-->
          <!--              target="_blank"-->
          <!--              >FAQ</a-->
          <!--            >-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              href="https://stockwayup.com/about"-->
          <!--              class="nav-link"-->
          <!--              target="_blank"-->
          <!--              >About Us</a-->
          <!--            >-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a-->
          <!--              href="https://stockwayup.com/contacts"-->
          <!--              class="nav-link"-->
          <!--              target="_blank"-->
          <!--              >Contacts</a-->
          <!--            >-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
