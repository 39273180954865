import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getSecurities: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .findAll("security", {
            include: "stock,bond,fund,quotes,currency,exchange",
            page: {
              size: payload.pageSize,
              cursor: payload.cursor,
            },
            sort: payload.sort,
            filter: {
              country_id: payload.country_id,
              exchange_id: payload.exchange_id,
              currency_id: payload.currency_id,
              sector_id: payload.sector_id,
              industry_id: payload.industry_id,
              name: payload.name,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getSecurity: (context, id) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .find("security", id, {
            include: "stock,bond,fund,quote,currency",
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getSecurityNews: (context, sid) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("security", sid)
          .all("news")
          .get({ include: "cats,source" })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getSecDayPrices: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("security", payload.sid)
          .all("day-price")
          .get({
            filter: {
              from: payload.from,
              to: payload.to,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getSecDayPricesPeriods: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("security", payload.sid)
          .all("day-price-period")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getQuarterlyBalanceSheet: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        let old = jsonApi.pluralize;
        jsonApi.pluralize = function (s) {
          return s;
        };
        jsonApi
          .one("securities", payload.sid)
          .all("quarterly-balance-sheet")
          .get({
            page: {
              size: payload.size,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
        jsonApi.pluralize = old;
      }),
    getAnnualBalanceSheet: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        let old = jsonApi.pluralize;
        jsonApi.pluralize = function (s) {
          return s;
        };
        jsonApi
          .one("securities", payload.sid)
          .all("annual-balance-sheet")
          .get({
            page: {
              size: payload.size,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
        jsonApi.pluralize = old;
      }),
    getQuarterlyIncomeStatement: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("securities", payload.sid)
          .all("quarterly-income-statement")
          .get({
            page: {
              size: payload.size,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getAnnualIncomeStatement: (context, payload) =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .one("securities", payload.sid)
          .all("annual-income-statement")
          .get({
            page: {
              size: payload.size,
            },
          })
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
  },
};
