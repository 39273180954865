<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">{{ t("Recently viewed") }}</h3>
        </div>
        <!--        <div class="col text-right">-->
        <!--          <a href="#!" class="btn btn-sm btn-primary">See all</a>-->
        <!--        </div>-->
      </div>
    </div>

    <el-table
      class="table-responsive table"
      :data="items"
      header-row-class-name="thead-light"
    >
      <el-table-column width="65px" prop="index">
        <template v-slot="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column :label="t('name')" min-width="130px" prop="name">
        <template v-slot="{ row }">
          <div class="font-weight-600">
            <router-link
              :to="{
                name: 'Security',
                params: { id: row.security.id, lang: $route.params.lang },
              }"
              ><span v-if="row.security.stock">{{
                row.security.stock.name
              }}</span>
              <span v-if="row.security.bond">{{ row.security.bond.name }}</span>
              <span v-if="row.security.fund">{{ row.security.fund.name }}</span>
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('ticker')" min-width="70px" prop="ticker">
        <template v-slot="{ row }">
          <span v-if="row.security.stock">{{ row.security.stock.ticker }}</span>
          <span v-if="row.security.bond">{{ row.security.bond.ticker }}</span>
          <span v-if="row.security.fund">{{ row.security.fund.ticker }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('price')" min-width="90px" prop="price">
        <template v-slot="{ row }">
          <span v-if="row.security.quote">
            <price :value="row.security.quote.close"></price>
            {{ row.security.currency.name }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="t('1d')" min-width="90px" prop="1d">
        <template v-slot="{ row }">
          <span
            class="text-success mr-2"
            v-if="
              row.security.quote &&
              row.security.quote.percent_change.search('\-') !== 0
            "
            ><i class="fa fa-arrow-up"></i>&nbsp;<change-price
              :value="row.security.quote.percent_change"
            ></change-price
            >%</span
          >

          <span
            class="text-warning mr-2"
            v-if="
              row.security.quote &&
              row.security.quote.percent_change.search('\-') === 0
            "
            ><i class="fa fa-arrow-down"></i>&nbsp;<change-price
              :value="row.security.quote.percent_change"
            ></change-price
            >%</span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { ElTable, ElTableColumn } from "element-plus";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import Price from "../Widgets/Price";
import ChangePrice from "../Widgets/ChangePrice";
import { useI18n } from "vue-i18n";

export default {
  name: "recently-viewed",
  components: {
    ChangePrice,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    Price,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === "RecentlyViewed") {
          document.title =
            this.t("Recently viewed") +
            " | " +
            this.t(
              "Stockwayup - progressive securities scoring service and portfolio tracker"
            );
        }
      },
    },
  },
  data() {
    return {
      items: [],
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  created() {
    if (!this.$store.getters.parsedToken) {
      return;
    }

    this.fetchData(this.$store.getters.parsedToken.uid);
  },
  methods: {
    fetchData(id) {
      this.$store
        .dispatch("getViewHistory", { uid: id, pageSize: 10 })
        .then(({ data }) => {
          this.items = data;
        })
        .catch((err) => {
          this.processError(err);
        });
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>
<style></style>
