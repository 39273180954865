<template>
  <modal :show="isShow">
    <form @submit="onSubmit" ref="form">
      <div class="row">
        <div class="col-md-12">
          <base-input
            alternative
            name="name"
            :placeholder="t('Portfolio name')"
            v-model="values.name"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="btn btn-success" v-if="!processing">
            {{ t("Save") }}
          </button>
          <button class="btn btn-light" disabled v-if="processing">
            {{ t("Save") }}
          </button>
        </div>

        <div class="col col-auto">
          <base-button type="link" class="ml-auto" @click="close"
            >{{ t("Close") }}
          </base-button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { useForm } from "vee-validate";
import {
  object as YupObject,
  setLocale as YupSetLocale,
  string as YupString,
} from "yup";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

export default {
  name: "EditModal",
  emits: ["closed", "updated"],
  components: {
    Modal,
  },
  props: {
    pid: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    YupSetLocale({
      mixed: {
        default: t("${path} is invalid", { path: "{path}" }),
        required: t("${path} is a required field", { path: "{path}" }),
      },
      string: {
        min: t("${path} must be at least ${min} characters", {
          path: "{path}",
          min: "{min}",
        }),
        max: t("${path} must be less than ot equal ${max} characters", {
          path: "{path}",
          max: "{max}",
        }),
      },
    });

    const schema = {
      values: {
        name: props.name,
      },
      validation: YupObject({
        name: YupString().min(2).max(255).required().label(t("Portfolio name")),
      }),
    };

    const { handleSubmit, setFieldValue, values, setErrors, validate } =
      useForm({
        validationSchema: schema.validation,
        initialValues: schema.values,
      });

    return {
      handleSubmit,
      setFieldValue,
      values,
      setErrors,
      validate,
      t,
    };
  },
  data: function () {
    return {
      processing: false,
      isShow: true,
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      this.processing = true;

      await this.validate().then((result) => {
        if (result.valid) {
          this.update();
        }
      });

      this.processing = false;
    },
    async update() {
      try {
        await this.$store.dispatch("updatePortfolio", {
          pid: this.pid,
          data: this.values,
        });

        this.runToast(
          "top-right",
          "success",
          this.t("Saved"),
          "ni ni-bold-down"
        );

        this.$emit("closed");
        this.$emit("updated", { pid: this.pid, name: this.values.name });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          this.setErrors(err.response.data.mappedErrors);
        } else {
          this.processError(err);
        }
      }
    },
    close() {
      this.$emit("closed");
    },
    processError(err) {
      if (err.response && err.response.status === 401) {
        this.runToast(
          "top-right",
          "danger",
          err.response.data.errors[0]["detail"],
          "ni ni-bell-55"
        );
      } else {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style scoped></style>
