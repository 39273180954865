<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <!--          <input class="form-control" placeholder="Search" type="text" />-->
          <el-select
            :placeholder="t('search')"
            v-model="securityId"
            filterable
            remote
            :remote-method="remoteSearch"
            :loading="loading"
            :clearable="clearable"
            class="search-select"
            @change="changeSearchValue"
          >
            <el-option
              v-for="item in stocks"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; font-weight: 600"
                >{{ item.quotePrice }} {{ item.currencyName }}
              </span>
              <span style="float: right; color: #8492a6; margin: 0 5px 0 5px"
                >{{ item.ticker }}
              </span>
            </el-option>
          </el-select>
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form>

    <ul class="navbar-nav align-items-lg-center ml-lg-auto">
      <li class="nav-item">
        <span v-if="locale === 'ru'">
          <router-link
            :to="{
              name: $route.name,
              params: Object.assign({}, $route.params, { lang: 'en' }),
            }"
            class="nav-link"
          >
            <span class="nav-link-inner--text">EN</span>
          </router-link>
        </span>
        <span v-else>
          <router-link
            :to="{
              name: $route.name,
              params: Object.assign({}, $route.params, { lang: 'ru' }),
            }"
            class="nav-link"
          >
            <span class="nav-link-inner--text">RU</span>
          </router-link>
        </span>
      </li>
    </ul>
    <hr class="d-lg-none" />

    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-lg-5">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>

      <base-dropdown
        class="nav-item"
        tag="li"
        title-classes="nav-link"
        title-tag="a"
        icon="ni ni-bell-55"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <!-- Dropdown header -->
        <div class="px-3 py-3">
          <h6 class="text-sm text-muted m-0">
            {{ t("You have") }} <strong class="text-primary">0</strong>
            {{ t("notifications.") }}
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <!--          <a href="#!" class="list-group-item list-group-item-action">-->
          <!--            <div class="row align-items-center">-->
          <!--              <div class="col-auto">-->
          <!--                &lt;!&ndash; Avatar &ndash;&gt;-->
          <!--                <img-->
          <!--                  alt="Image placeholder"-->
          <!--                  src="img/theme/team-1.jpg"-->
          <!--                  class="avatar rounded-circle"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="col ml&#45;&#45;2">-->
          <!--                <div class="d-flex justify-content-between align-items-center">-->
          <!--                  <div>-->
          <!--                    <h4 class="mb-0 text-sm">User</h4>-->
          <!--                  </div>-->
          <!--                  <div class="text-right text-muted">-->
          <!--                    <small>2 hrs ago</small>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <p class="text-sm mb-0">-->
          <!--                  Let's meet at Starbucks at 11:30. Wdyt?-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a>-->
          <!--          <a href="#!" class="list-group-item list-group-item-action">-->
          <!--            <div class="row align-items-center">-->
          <!--              <div class="col-auto">-->
          <!--                &lt;!&ndash; Avatar &ndash;&gt;-->
          <!--                <img-->
          <!--                  alt="Image placeholder"-->
          <!--                  src="img/theme/team-2.jpg"-->
          <!--                  class="avatar rounded-circle"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="col ml&#45;&#45;2">-->
          <!--                <div class="d-flex justify-content-between align-items-center">-->
          <!--                  <div>-->
          <!--                    <h4 class="mb-0 text-sm">User</h4>-->
          <!--                  </div>-->
          <!--                  <div class="text-right text-muted">-->
          <!--                    <small>3 hrs ago</small>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <p class="text-sm mb-0">-->
          <!--                  A new issue has been reported for Argon.-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a>-->
          <!--          <a href="#!" class="list-group-item list-group-item-action">-->
          <!--            <div class="row align-items-center">-->
          <!--              <div class="col-auto">-->
          <!--                &lt;!&ndash; Avatar &ndash;&gt;-->
          <!--                <img-->
          <!--                  alt="Image placeholder"-->
          <!--                  src="img/theme/team-3.jpg"-->
          <!--                  class="avatar rounded-circle"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="col ml&#45;&#45;2">-->
          <!--                <div class="d-flex justify-content-between align-items-center">-->
          <!--                  <div>-->
          <!--                    <h4 class="mb-0 text-sm">User</h4>-->
          <!--                  </div>-->
          <!--                  <div class="text-right text-muted">-->
          <!--                    <small>5 hrs ago</small>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <p class="text-sm mb-0">Your posts have been liked a lot.</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a>-->
          <!--          <a href="#!" class="list-group-item list-group-item-action">-->
          <!--            <div class="row align-items-center">-->
          <!--              <div class="col-auto">-->
          <!--                &lt;!&ndash; Avatar &ndash;&gt;-->
          <!--                <img-->
          <!--                  alt="Image placeholder"-->
          <!--                  src="img/theme/team-5.jpg"-->
          <!--                  class="avatar rounded-circle"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="col ml&#45;&#45;2">-->
          <!--                <div class="d-flex justify-content-between align-items-center">-->
          <!--                  <div>-->
          <!--                    <h4 class="mb-0 text-sm">User</h4>-->
          <!--                  </div>-->
          <!--                  <div class="text-right text-muted">-->
          <!--                    <small>2 hrs ago</small>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <p class="text-sm mb-0">-->
          <!--                  Let's meet at Starbucks at 11:30. Wdyt?-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a>-->
          <!--          <a href="#!" class="list-group-item list-group-item-action">-->
          <!--            <div class="row align-items-center">-->
          <!--              <div class="col-auto">-->
          <!--                &lt;!&ndash; Avatar &ndash;&gt;-->
          <!--                <img-->
          <!--                  alt="Image placeholder"-->
          <!--                  src="img/theme/team-5.jpg"-->
          <!--                  class="avatar rounded-circle"-->
          <!--                />-->
          <!--              </div>-->
          <!--              <div class="col ml&#45;&#45;2">-->
          <!--                <div class="d-flex justify-content-between align-items-center">-->
          <!--                  <div>-->
          <!--                    <h4 class="mb-0 text-sm">User</h4>-->
          <!--                  </div>-->
          <!--                  <div class="text-right text-muted">-->
          <!--                    <small>3 hrs ago</small>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <p class="text-sm mb-0">-->
          <!--                  A new issue has been reported for Argon.-->
          <!--                </p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a>-->
        </div>
        <!-- View all -->
        <!--        <a-->
        <!--          href="#!"-->
        <!--          class="dropdown-item text-center text-primary font-weight-bold py-3"-->
        <!--          >View all</a-->
        <!--        >-->
      </base-dropdown>
      <!--      <base-dropdown-->
      <!--        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"-->
      <!--        class="nav-item"-->
      <!--        tag="li"-->
      <!--        title-tag="a"-->
      <!--        title-classes="nav-link"-->
      <!--        icon="ni ni-ungroup"-->
      <!--      >-->
      <!--        <div class="row shortcuts px-4">-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span class="shortcut-media avatar rounded-circle bg-gradient-red">-->
      <!--              <i class="ni ni-calendar-grid-58"></i>-->
      <!--            </span>-->
      <!--            <small>Calendar</small>-->
      <!--          </a>-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span-->
      <!--              class="shortcut-media avatar rounded-circle bg-gradient-orange"-->
      <!--            >-->
      <!--              <i class="ni ni-email-83"></i>-->
      <!--            </span>-->
      <!--            <small>Email</small>-->
      <!--          </a>-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span class="shortcut-media avatar rounded-circle bg-gradient-info">-->
      <!--              <i class="ni ni-credit-card"></i>-->
      <!--            </span>-->
      <!--            <small>Payments</small>-->
      <!--          </a>-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span-->
      <!--              class="shortcut-media avatar rounded-circle bg-gradient-green"-->
      <!--            >-->
      <!--              <i class="ni ni-books"></i>-->
      <!--            </span>-->
      <!--            <small>Reports</small>-->
      <!--          </a>-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span-->
      <!--              class="shortcut-media avatar rounded-circle bg-gradient-purple"-->
      <!--            >-->
      <!--              <i class="ni ni-pin-3"></i>-->
      <!--            </span>-->
      <!--            <small>Maps</small>-->
      <!--          </a>-->
      <!--          <a href="#!" class="col-4 shortcut-item">-->
      <!--            <span-->
      <!--              class="shortcut-media avatar rounded-circle bg-gradient-yellow"-->
      <!--            >-->
      <!--              <i class="ni ni-basket"></i>-->
      <!--            </span>-->
      <!--            <small>Shop</small>-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </base-dropdown>-->
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="/img/theme/team-5.jpg" />
              </span>
              <!--              <div class="media-body ml-2 d-none d-lg-block">-->
              <!--                <span class="mb-0 text-sm font-weight-bold">User</span>-->
              <!--              </div>-->
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">{{ t("Welcome!") }}</h6>
        </div>

        <router-link
          :to="{
            name: 'Profile',
            params: { lang: $route.params.lang },
          }"
          class="dropdown-item"
        >
          <i class="ni ni-single-02"></i>
          <span>{{ t("My profile") }}</span>
        </router-link>

        <!--        <a href="#!" class="dropdown-item">-->
        <!--          <i class="ni ni-settings-gear-65"></i>-->
        <!--          <span>Settings</span>-->
        <!--        </a>-->
        <!--        <a href="#!" class="dropdown-item">-->
        <!--          <i class="ni ni-calendar-grid-58"></i>-->
        <!--          <span>Activity</span>-->
        <!--        </a>-->
        <a href="mailto:support@stockwayup.com" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>{{ t("Support") }}</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#!" @click.prevent="logout" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>{{ t("Logout") }}</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import router from "@/router";
import { ElSelect, ElOption } from "element-plus";
import _ from "lodash";
import Big from "big.js";
import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseNav,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: true,
      searchQuery: "",
      loading: false,
      clearable: true,

      stocks: [],
      securityId: "",
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");

        this.runToast(
          "top-right",
          "success",
          "See you later!",
          "ni ni-time-alarm"
        );

        router.push({ path: "/login" });
      } catch (err) {
        this.runToast(
          "top-right",
          "danger",
          this.t("Network error. Please try again."),
          "ni ni-bell-55"
        );
      }
    },
    remoteSearch: _.debounce(function debounceSearch(query) {
      if (query === "") {
        this.stocks = [];

        return;
      }

      this.loading = true;

      this.$store
        .dispatch("getSecurities", {
          pageSize: 18,
          sort: "-quote.close",
          name: query,
        })
        .then(({ data }) => {
          this.loading = false;

          this.stocks = data.map((item) => {
            let quotePrice = "";

            if (item.quote) {
              quotePrice = this.formatPrice(item.quote.close);
            }

            return {
              id: item.id,
              name: item.stock.name,
              ticker: item.stock.ticker,
              exchangeName: item.exchange.name,
              currencyName: item.currency.name,
              quotePrice: quotePrice,
            };
          });
        })
        .catch(() => {
          this.loading = false;
          this.stocks = [];

          this.runToast(
            "top-right",
            "danger",
            this.t("Network error. Please try again."),
            "ni ni-bell-55"
          );
        });
    }, 500),
    changeSearchValue(secId) {
      this.stocks = [];
      this.securityId = "";

      this.$router.push({ name: "Security", params: { id: secId } });
    },
    formatPrice(val) {
      if (val === "") {
        return val;
      }

      let bigVal = new Big(val);

      if (bigVal.cmp(new Big(1)) === 1) {
        return bigVal.toFixed(2);
      }

      return val;
    },
    runToast(pos, type, text, icon) {
      const content = {
        component: Notification,
        props: {
          ownText: "",
          ownIcon: "",
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: false,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
};
</script>

<style>
.search-select {
  width: 250px !important;
  background-color: transparent;
  border: 0;
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-select .el-input__inner {
  padding-left: 0;
  border: 0 !important;
  background-color: transparent;
  color: #000;
}

/*.search-select .el-input__suffix {*/
/*  display: none;*/
/*}*/
</style>
