<template>
  <div class="card widget-calendar">
    <div class="card-header">
      <div class="h3 mb-0 widget-calendar-day">{{ t("Events calendar") }}</div>
    </div>

    <div class="card-body">
      <div id="widget-calendar" class="widget-calendar"></div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import Tooltip from "tooltip.js";
import { useI18n } from "vue-i18n";

let calendar;

export default {
  name: "calendar",
  components: {},
  emits: ["updateInterval"],
  props: {
    earnings: {
      type: Array,
      default: () => [],
    },
    dividends: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    earnings(earnings) {
      this.updateEarnings(earnings);
    },
    dividends(dividends) {
      this.updateDividends(dividends);
    },
  },
  data() {
    return {
      events: [],
    };
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  methods: {
    initCalendar() {
      let calendarEl = document.getElementById("widget-calendar");
      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        views: {
          dayGridMonth: {
            dayMaxEventRows: 2,
          },
        },
        eventDidMount: function (info) {
          new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            placement: "top",
            trigger: "hover",
            html: true,
            innerSelector: ".el-popper",
            template: '<div class="el-popover el-popper" role="tooltip"></div>',
          });
        },
        fixedWeekCount: true,
        selectable: false,
        headerToolbar: false,
        contentHeight: "auto",
        buttonIcons: {
          prev: " ni ni-bold-left",
          next: " ni ni-bold-right",
        },
        dayCellClassNames: "text-nowrap",
        editable: false,
        events: this.events,
      });

      this.updateEarnings(this.earnings);

      calendar.render();

      this.$emit("updateInterval", {
        from: calendar.view.activeStart,
        to: calendar.view.activeEnd,
      });
    },
    updateEarnings(earnings) {
      earnings.forEach((model) => {
        let description =
          "<div class='el-popover__title'>" +
          model.stock.name +
          "</div>" +
          "<div><strong>" +
          this.t("Type") +
          ":</strong> " +
          this.t("earning release") +
          "</div>" +
          "<div><strong>" +
          this.t("Time") +
          ":</strong> " +
          this.t(model.time) +
          "</div>";

        if (model.eps_actual) {
          description +=
            "<div>" + this.t("EPS actual") + ": " + model.eps_actual + "</div>";
        }

        if (model.eps_estimate) {
          description +=
            "<div><strong>" +
            this.t("EPS estimate") +
            ":</strong> " +
            model.eps_estimate +
            "</div>";
        }

        if (model.difference) {
          description +=
            "<div>" + this.t("Difference") + ": " + model.difference + "</div>";
        }

        if (model.surprise_prc) {
          description +=
            "<div>" +
            this.t("Surprise prc") +
            ": " +
            model.surprise_prc +
            "</div>";
        }

        calendar.addEvent({
          id: model.id,
          title: model.stock.ticker,
          description: description,
          start: Date.parse(model.date),
          className: "event-default",
          allDay: true,
        });
      });
    },
    updateDividends(dividends) {
      dividends.forEach((model) => {
        let description =
          "<div class='el-popover__title'>" +
          model.stock.name +
          "</div>" +
          "<div><strong>" +
          this.t("Type") +
          ":</strong> " +
          this.t("dividend payment") +
          " </div>" +
          "<div><strong>" +
          this.t("Amount") +
          ":</strong> " +
          model.amount +
          "</div>" +
          "<div><strong>" +
          this.t("Currency") +
          ":</strong> " +
          model.currency +
          "</div>";

        calendar.addEvent({
          id: model.id,
          title: model.stock.ticker,
          description: description,
          start: Date.parse(model.date),
          className: "bg-success",
          allDay: true,
        });
      });
    },
  },
  mounted() {
    if (this.$store.getters.parsedToken) {
      this.initCalendar();
    }
  },
};
</script>
<style lang="scss">
.widget-calendar {
  line-height: 1;
}

.el-popover-row {
  display: inline-block;
}
</style>
