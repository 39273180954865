<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "portfolios-layout",
};
</script>

<style scoped></style>
