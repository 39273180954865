import jsonApi from "@/jsonApi";

const accessToken = "accessToken";

export default {
  state: {},

  actions: {
    getCurrencies: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("currencies")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getExchanges: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("exchanges")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getSectors: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("sectors")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
    getIndustries: () =>
      new Promise((resolve, reject) => {
        jsonApi.bearer = localStorage.getItem(accessToken);
        jsonApi
          .all("industries")
          .get()
          .then((res) => resolve(res))
          .catch((errors) => reject(errors));
      }),
  },
};
